/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unneeded-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Card,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import EmojiPicker from "emoji-picker-react";
// components
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    orderBy,
    query,
    Timestamp,
    updateDoc,
    where,
} from 'firebase/firestore';

import { deepOrange } from '@mui/material/colors';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import moment from 'moment';
import { DatePicker } from '@mui/lab';
import { db } from '../../../../../firebase.config';
import Iconify from '../../../../../components/Iconify';
import { UserAuth } from '../../../../../context/AuthContext';
import { Variables } from '../../../../../context/VariableContext';
import CustomSeanceDateTimePicker from '../../../../../components/customSeanceDateTimePicker';
import CustomDateTimePicker from '../../../../../components/CustomDateTimePicker';
import CustomButton from '../../../../../components/CustomButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function CreateSeance({ eventID, date, seanceID }) {
    const { user } = UserAuth();
    const { setOpenDrawer } = Variables();
    const [step, setStep] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [dateDebut, setDateDebut] = useState(null);
    const [timeDebut, setTimeDebut] = useState('');
    const [name, setName] = useState('');
    const [typeBillet, setTypeBillet] = React.useState(0);
    const [tickets, setTicket] = React.useState([]);
    const [selectedTickets, setSelectedTickets] = React.useState([]);
    const [totalselectedTickets, setTotalSelectedTicket] = React.useState([]); //
    const [QuotaselectedTickets, setQuotaSelectedTicket] = React.useState([]);

    const [addTicket, setAddTicket] = React.useState(false);

    const [recurence, setRecurence] = React.useState();

    const handleChange = (event) => {
        setRecurence(event.target.value);
    };


    const handleDateChange = (newDate) => {
        setDateDebut(newDate);
    };

    const handleTimeDebutChange = (newTime) => {
        console.log('parent')
        setTimeDebut(newTime);
    };
    const [timeFin, setTimeFin] = useState('');


    const handleTimeFinChange = (newTime) => {
        setTimeFin(newTime);
    };

    const handleTimestamp = (e) => format((new Date(e.toJSON())), 'yyyy-MM-dd', { locale: fr }).toString();

    const handleTimestampCustom = (e) => format((new Date(e.toJSON())), 'HH:mm', { locale: fr }).toString();

    // gestion des ticket a selectionner selon les dates
    /* const handleSelectedTickets = (index, i) => {
        console.log(index, i);
        const tmp = [];

        console.log('all: ', totalselectedTickets);
        totalselectedTickets.
                const tmp1 = [];
                console.log('a', a);
                for (const element in a) {
                    console.log('element', element);
                    console.log('element a', a[element]);
                    console.log('i', i);

                    if (parseInt(element) === i) {
                        if (a[element] === false) {
                            tmp1.push(true);
                        } else {
                            tmp1.push(false);
                        }
                    } else {
                        tmp1.push(a[element]);
                    }
                }

                tmp.push(tmp1);
            

        setTotalSelectedTicket(tmp);
    }; */
    const handleSelectedTickets = (index) => {
        console.log(index)
        setSelectedTickets((prevArray) =>
            prevArray.map((item, i) => (i === index ? !item : item))
        );
    };

    const handleAffinageQuota = (value, index) => {
        console.log(index)
        setQuotaSelectedTicket((prevArray) =>
            prevArray.map((item, i) => (i === index ? value : item))
        );
    };

    const formatDateWithTime = (date, timeDebut, timeFin) => {
        const tmpDateDebut = new Date(date);
        const [startHours, startMinutes] = timeDebut.split(':').map(Number);
        tmpDateDebut.setHours(startHours, startMinutes, 0);

        const tmpDateFin = new Date(date);
        const [endHours, endMinutes] = timeFin.split(':').map(Number);
        tmpDateFin.setHours(endHours, endMinutes, 0);

        // Si la date de fin est inférieure à la date de début, ajoutez un jour
        if (tmpDateFin < tmpDateDebut) {
            tmpDateFin.setDate(tmpDateFin.getDate() + 1);
        }

        return {
            dateDebut: tmpDateDebut,
            dateFin: tmpDateFin,
        };
    };


    React.useEffect(() => {

        try {
            setLoading(true);
            console.log(date.start)
            setName(date.title)
            setDateDebut(handleTimestamp(date.start))
            setTimeDebut(handleTimestampCustom(date.start))
            // setTimeFin(handleTimestampCustom(da.dateFin))
            setLoading(false);
        } catch (error) {
            console.log('error');
        }

    }, [date]);

    const createSeance = async () => {
        setLoading(true)
        try {
            const tmpDate = formatDateWithTime(dateDebut, timeDebut, timeFin);
            // const tmpDateFin = formatDateWithTime(dateDebut, timeFin);
            console.log(tmpDate)
            console.log(QuotaselectedTickets)
            console.log(selectedTickets)
            // gestion de ticket sélectionné
            const tmpTickets = [];
            // boucle des tickets
            for (let j = 0; j < tickets.length; j++) {
                if (selectedTickets[j] === true) {
                    tmpTickets.push({
                        id: tickets[j].id,
                        quota: parseInt(QuotaselectedTickets[j]),
                        sell: parseInt(0),
                        status: parseInt(1),
                    });
                }
            }
            await addDoc(collection(db, `evenement/${eventID}/seances`), {
                name,
                date: Timestamp.fromDate(tmpDate.dateDebut),
                fin: Timestamp.fromDate(tmpDate.dateFin),
                billets: tmpTickets,
                status: parseInt(1),
                participants: parseInt(0),
            });
            setOpenDrawer(false);
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    };

    /*
        // create population
        const createPopulation = async () => {
            setLoading(true)
            try {
                console.log('salut')
                console.log(orgID)
                await addDoc(collection(db, `organisations/${orgID}/crm/${orgID}/populations`), {
                    emoji: chosenEmoji,
                    name: nom,
                    description: desc
                });
                setOpenDrawer(false);
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }; */
    /*
        // save population
        const savePopulation = async () => {
            setLoading(true)
            try {
                console.log('salut')
                console.log(orgID)
                
                await updateDoc(doc(db, `organisations/${orgID}/crm/${orgID}/populations`, populationID), {
                    emoji: chosenEmoji,
                    name: nom,
                    description: desc ? desc : null,
                    filter: {filterEvent, filterBillet:{minBillet, maxBillet}, filterNewsletter, filterNotif, filterAbonne, filterHist},
                    updatedAt: new Date(),
                  });
                setOpenDrawer(false);
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        };
    */

    // recuperation de tickets
    React.useEffect(() => {
        (async () => {
            setTicket([]);
            setSelectedTickets([])
            try {
                setLoading(true);
                const q = query(collection(db, `evenement/${eventID}/tickets`));
                const snapshot = await getDocs(q);
                snapshot.forEach((d) => {
                    setTicket((tickets) => [...tickets, d]);
                    console.log(d.id, ' => ', d.data());
                    setSelectedTickets((tickets) => [...tickets, false]);
                    setQuotaSelectedTicket((quotas) => [...quotas, 0]);
                });
                setLoading(false);

            } catch (error) {
                console.log('error');
            }
        })();
    }, []);
    return (
        <Box>
            {
                // eslint-disable-next-line no-nested-ternary

                <Box
                    paddingBottom={3}
                    paddingX={3}
                    sx={{ height: { xs: '84vh', sm: ' 80.25vh', xl: '84.25vh' }, overflowX: 'auto', overflowY: 'auto' }}
                >
                    <Stack spacing={2} >
                        <Typography variant="h5" paddingBottom={1} paddingTop={1}>
                            Ajouter des seances
                        </Typography>
                        {
                            recurence === 0 && <TextField disabled={loading ? true : false} value={name} onChange={(e) => setName(e.target.value)} placeholder='Sous-titre : jour1, manche 1 ...' />

                        }
                        <Box>

                            <CustomSeanceDateTimePicker disabled={loading} date={dateDebut} debutTime={timeDebut} onDebutTimeChange={handleTimeDebutChange} onDateChange={handleDateChange} endTime={timeFin} onEndTimeChange={handleTimeFinChange} />

                        </Box>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Recurence de votre seance</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={recurence}
                                label="Recurence de votre seance"
                                onChange={handleChange}
                            >
                                <MenuItem value={0}>Une fois</MenuItem>
                                <MenuItem value={1}>Hebdomadaire</MenuItem>
                                <MenuItem value={3}>Mensuel</MenuItem>
                            </Select>
                        </FormControl>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='subtitle1'>Billets Associés</Typography>
                            {

                            }
                            <Typography variant='caption' sx={{ color: 'red' }}>Accun billet associé</Typography>
                        </Stack>

                        <Stack spacing={2}>
                            {
                                /** <Stack direction='row' spacing={1}>
                                <Box onClick={() => setTypeBillet(0)} sx={typeBillet === 0 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                                    :
                                    { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                                    <Typography variant='body2'>Tous les billets</Typography>
                                </Box>
                                <Box onClick={() => setTypeBillet(1)} sx={typeBillet === 1 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                                    :
                                    { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                                    <Typography variant='body2'>Billets specifiques</Typography>
                                </Box>
                            </Stack> */
                            }

                            {tickets.map((ticket, i) => (
                                <>
                                    <Stack sx={{ border: '2px solid gainsboro', borderRadius: '10px' }} paddingY={1} paddingX={2} key={ticket.id}>
                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography variant='body2'>{ticket.data().ticket_name}</Typography>
                                            <Checkbox
                                                disabled={loading}
                                                checked={selectedTickets[i]}
                                                onChange={() => {
                                                    handleSelectedTickets(i);
                                                }}

                                            />
                                        </Stack>
                                        {selectedTickets[i] && (
                                            <TextField disabled={loading} placeholder='quantite de billet mise en vente' value={QuotaselectedTickets[i]}
                                                onChange={(e) => {
                                                    handleAffinageQuota(e.target.value, i);
                                                }} />
                                        )}
                                    </Stack>
                                </>
                            ))}

                        </Stack>
                    </Stack>
                </Box>
            }
            <Stack direction='row' spacing={2} padding={2} justifyContent={'right'} alignItems={'center'} sx={{ borderTop: '1px solid gainsboro' }}>
                <CustomButton onClick={() => {
                    if (step === 0) {
                        setDateDebut(null)
                        setOpenDrawer(false);
                    }
                    else {
                        setStep(0)
                    }

                }} disabled={loading} type={'secondaire'} buttonText={'Annuler'} />
                <CustomButton onClick={createSeance} disabled={loading} type={'primaire'} buttonText={'Ajouter'} />
            </Stack>
        </Box>
    );
}
