import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,

    Stack,

    Button,

    Container,
    Typography,

    Grid,

    Box,
    Modal,
    TextField,

    MenuItem,

    IconButton,
    Backdrop,
    CircularProgress,
    Menu,
    Divider,
    styled,
    alpha,
    LinearProgress,
    Avatar,
} from '@mui/material';
// components
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from 'firebase/firestore';
import Page from '../../../../../components/Page';
import Iconify from '../../../../../components/Iconify';
import { db } from '../../../../../firebase.config';
import CheckCard from '../../../../../components/CheckCard';
import { UserAuth } from '../../../../../context/AuthContext';
import CustomButton from '../../../../../components/CustomButton';


export default function FacebookLinkAccount() {

    const handleConnect = () => {
        const appId = '9736171633120398';
        const redirectUri = 'https://choptonticket.com/facebook/callback';
        const scope = 'ads_management,ads_read,business_management';
        const authUrl = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`;
    
        window.location.href = authUrl;
      };


    return (

        <Box>
            <Stack paddingTop={3} spacing={3}>
                <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-evenly' sx={{ border: '1px solid gainsboro', borderRadius: '7px' }}>
                    <Stack padding={4} direction='row'>
                        <Avatar sx={{ width: 70, height: 70 }}>
                            <Iconify icon='logos:facebook' sx={{ width: 50, height: 50 }} />
                        </Avatar>

                        <Box sx={{ display: 'flex', alignItems: 'end' }}>
                            <Iconify icon='mdi:link' sx={{ width: 20, height: 20 }} />
                        </Box>

                    </Stack>
                    <Stack spacing={2} padding={2}>
                        <Typography variant='h4'>Facebook</Typography>
                        <Typography variant='body2'>Configurez des campagnes publicitaires et partagez des événements sur votre page Facebook lorsque vous connectez votre compte avec Choptonticket.
                        </Typography>
                        <CustomButton type='tertiaire' buttonText='Connecter votre compte Facebook' onClick={handleConnect} />
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}
