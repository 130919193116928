import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation, useParams, useNavigate } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Divider,
  Card,
  Grid,
  Typography,
  ListItem,
  ListItemAvatar,
  Link,
  Alert,
  AlertTitle,
  Stack,
  Snackbar,
  Button,
} from '@mui/material';
//
import { doc, getDoc, getCountServer, query, collection, getCountFromServer } from 'firebase/firestore';
import { db } from '../firebase.config';
import Iconify from './Iconify';
import CustomButton from './CustomButton';

// ----------------------------------------------------------------------

export default function CheckCard() {
  const { pathname } = useLocation();
  const { eventID } = useParams();
  const [event, setEvent] = useState();
  const [org, setOrg] = useState();
  const [ticketCount, setTicketCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [outdated, setOutdated] = useState(false);

  //  snack bar
  const [openSnack, setOpenSnack] = useState(true);
  const [msg, setMsg] = useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[0];
  };

  // recuperation des données
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const today = new Date();
        const today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate());

        const docRef0 = doc(db, 'evenement', `${eventID}`);

        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEvent(docSnap.data());
          console.log('outdated---------------------------');
          console.log(docSnap.data().dateFin);
          console.log(today1);
          if (new Date(handleTimestamp(docSnap.data().dateFin)) < today1) {
            console.log('outdated---------------------------');
            setOutdated(true);
          }
          console.log('outdated---------------------------');
          const docSnap1 = await getDoc(doc(db, 'organisations', `${docSnap.data().orgId}`));
          setOrg(docSnap1.data());
          console.log(docSnap.data());
          // count
          const snapshot = await getCountFromServer(query(collection(db, `evenement/${eventID}/tickets`)));
          setTicketCount(snapshot.data().count);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, [eventID]);

  return (
    <>
      {event && (
        <>

          {!loading && (org.certified === false || event.idVisuel === '' || ticketCount === 0 || (!loading && outdated)) ? (
            <Box sx={{ border: '2px solid gainsboro', borderRadius: '7px' }} padding={2}>
              <Typography variant='h6'>{(!loading && outdated) ? 'Evenement terminé' : 'Etapes restantes avant publication'}</Typography>
              <Stack spacing={2} paddingY={2}>
                {event.idVisuel === '' ? (<Stack direction='row' spacing={1} >
                  <Iconify icon='material-symbols-light:image-outline' sx={{ width: 24, height: 24 }} />
                  <Typography variant='body2'>Ajouter une photo a votre evenement</Typography>
                  <CustomButton type='tertiaire' buttonText='ajout image' onClick={() => {
                    navigate(`/${eventID}/dashboard/billets/information`);
                  }} />
                </Stack>) : null}
                {ticketCount === 0 ? (<Stack direction='row' spacing={1} alignItems='center' >
                  <Iconify icon='fluent:ticket-horizontal-20-regular' sx={{ width: 24, height: 24 }} />
                  <Typography variant='body2'>Ton event n'a pas encore de tickets.</Typography>
                  <CustomButton type='tertiaire' buttonText='ajout de ticket' onClick={() => {
                    navigate(`/${eventID}/dashboard/billets/nouveau_billet`);
                  }} />
                </Stack>) : null}
                {org.certified === false ? (<Stack direction='row' spacing={1} >
                  <Iconify icon='material-symbols-light:settings-outline' sx={{ width: 24, height: 24 }} />
                  <Typography variant='body2'>Terminer la configuration de votre organisation</Typography>
                </Stack>) : null}
                {!loading && outdated ? (<Stack direction='row' spacing={1} alignItems={'center'}>
                  <Typography variant='body2'>Cet évenement est terminé, vous pouvez maintenant le clos</Typography>
                  <Button
                    onClick={() => {
                      navigate(`/${eventID}/dashboard/billets`);
                    }}
                    variant="text"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: '#5E17EB',
                      backgroundColor: 'transparent',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#5E17EB',
                        borderColor: 'transparent',
                      },
                    }}
                  >
                    cliquer ici
                  </Button>
                </Stack>) : null}
                

              </Stack>
            </Box>
          ) : null}
        </>
      )}
    </>
  );
}
