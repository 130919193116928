import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation, useParams, useNavigate } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Divider,
  Card,
  Grid,
  Typography,
  ListItem,
  ListItemAvatar,
  Link,
  Alert,
  AlertTitle,
} from '@mui/material';
//
import { doc, getDoc, getCountServer, query, collection, getCountFromServer } from 'firebase/firestore';
import { db } from '../firebase.config';
import Iconify from './Iconify';
import { UserAuth } from '../context/AuthContext';

// ----------------------------------------------------------------------

export default function CheckOrg() {
  const { pathname } = useLocation();
  const { user } = UserAuth();
  const { eventID } = useParams();
  const [event, setEvent] = useState();
  const [org, setOrg] = useState();
  const [ticketCount, setTicketCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [profileComplete, setProfileComplete] = useState(true);
  // recuperation des données
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', `${user.uid}`);

        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEvent(docSnap.data());
          const docSnap1 = await getDoc(doc(db, 'organisations', `${docSnap.data().idOrg}`));
          setOrg(docSnap1.data());
          const bioCoord = doc(db, `organisations/${docSnap.data().idOrg}/Bio`, `${docSnap.data().idOrg}`);
          const bioSnap = await getDoc(bioCoord);
          if (docSnap1.data().certified && docSnap1.data().idVisuel != null && docSnap1.data().bannerVisuel != null && bioSnap?.data()?.bio != null) {
            setProfileComplete(true)
          } else {
            setProfileComplete(false)
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, [user]);

  return (
    <>
      {org && (
        <>
          {!loading  ? (
            <>
              {(!profileComplete ) ? (
                <Alert severity="info" >
                  <AlertTitle>Completer votre profile organisateur</AlertTitle>
                  Un profil compet peut améliorer la visibilité de votre événement sur les moteur de recherche, mettre en valeur votre marque et renforcer la confiance des participants —{' '}
                  <Typography variant='caption'
                    onClick={() => {
                      navigate(`/organisation/app`);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <strong>Cliquez ici</strong>
                  </Typography>
                </Alert>
              ) : null}
              {org.infoPaie === 0 ? (
                <Alert severity="error" sx={{ marginBottom: 2 }}>
                  <AlertTitle>Informations de paiement</AlertTitle>
                  Veuillez compléter dès à present vos informations de paiement pour recevoir vos recette —{' '}
                  <Typography variant='caption'
                    onClick={() => {
                      navigate(`/finance/settings`);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <strong>Cliquez ici</strong>
                  </Typography>
                </Alert>
              ) : null}
            </>
          ) : null}
        </>
      )}
    </>
  );
}
