import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import './customStyles.css'; // Vos propres styles
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { orderBy } from "lodash";
import { useParams } from "react-router-dom";
import { db } from '../../../firebase.config';
import Swipeable from "../../crm/component/swiper";
import { Variables } from "../../../context/VariableContext";

// Localizer pour le calendrier
const localizer = momentLocalizer(moment);

const Date = () => {
  const [events, setEvents] = useState([]); // Liste des événements
  const [dialogOpen, setDialogOpen] = useState(false); // Gestion de la boîte de dialogue
  const [selectedEvent, setSelectedEvent] = useState(null); // Événement sélectionné
  const [newEventTitle, setNewEventTitle] = useState(""); // Titre du nouvel événement
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreh, setRefresh] = useState(false);
  const { eventID } = useParams();

  // Ouvrir la boîte de dialogue pour ajouter ou modifier un événement
  const handleSelectSlot = (slotInfo) => {
    setSelectedEvent({ start: slotInfo.start, end: slotInfo.end });
    setType(7)
    setOpenDrawer(true)
    // setDialogOpen(true);
  };

  // Ajouter un nouvel événement ou modifier un existant
  const handleSaveEvent = () => {
    if (newEventTitle.trim() === "") return;
    if (selectedEvent.id) {
      // Modification d'un événement existant
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === selectedEvent.id
            ? { ...event, title: newEventTitle }
            : event
        )
      );
    } else {
      // Ajout d'un nouvel événement
      setEvents((prevEvents) => [
        ...prevEvents,
        {
          id: Math.random().toString(36).substr(2, 9), // Générer un ID unique
          title: newEventTitle,
          start: selectedEvent.start,
          end: selectedEvent.end,
        },
      ]);
    }
    setDialogOpen(false);
    setNewEventTitle("");
    setSelectedEvent(null);
  };

  // Supprimer un événement
  const handleDeleteEvent = () => {
    setEvents((prevEvents) =>
      prevEvents.filter((event) => event.id !== selectedEvent.id)
    );
    setDialogOpen(false);
    setSelectedEvent(null);
  };

  // Ouvrir un événement existant
  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setNewEventTitle(event.title);
    // setDialogOpen(true);
    setType(7)
    setOpenDrawer(true)
  };


  // Fonction pour récupérer les événements depuis Firestore
  const fetchSeancesFromFirestore = async () => {
    setDates([]);
        try {
          setLoading(true);
          // event
          const docRef0 = doc(db, 'evenement', eventID);
          const docSnap = await getDoc(docRef0);
          if (docSnap.exists()) {
            // setEvent(docSnap.data());
          }
          // recuperation des tickets
          // const q1 = query(collection(db, `evenement/${eventID}/tickets`));
          // const snapshot1 = await getDocs(q1);
          // snapshot1.forEach((d) => {
          //  setTickets((tickets) => [...tickets, d]);
          // });
  
          // recuperation des dates
          const q = query(collection(db, `evenement/${eventID}/seances`), orderBy('date', 'asc'));
          const snapshot = await getDocs(q);
          const firebaseEvents = snapshot.docs.map((doc) => {
            const data = doc.data();
            console.log(data.date)
            // Conversion des dates
      const startDate = data.date.toDate ? data.date.toDate() : new Date(data.date);
      const endDate = data.fin.toDate ? data.fin.toDate() : new Date(data.fin);
            return {
              id: doc.id,
              title: data.name ? data.name : 'test', // Titre de l'événement
              start: startDate, // Date de début
              end: endDate, // Date de fin
            };
          });
          setDates(firebaseEvents);
          
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
  };

    // GESTION DE LA MODIFICATION
    const { openDrawer, setOpenDrawer } = Variables();
    const { type, setType } = Variables();

  useEffect(() => {
      (async () => {
        // setTickets([]);
        setDates([]);
        try {
          setLoading(true);
          // event
          const docRef0 = doc(db, 'evenement', eventID);
          const docSnap = await getDoc(docRef0);
          if (docSnap.exists()) {
            // setEvent(docSnap.data());
          }
          // recuperation des tickets
          // const q1 = query(collection(db, `evenement/${eventID}/tickets`));
          // const snapshot1 = await getDocs(q1);
          // snapshot1.forEach((d) => {
          //  setTickets((tickets) => [...tickets, d]);
          // });
  
          // recuperation des dates
          const q = query(collection(db, `evenement/${eventID}/seances`), orderBy('date', 'asc'));
          const snapshot = await getDocs(q);
          const firebaseEvents = snapshot.docs.map((doc) => {
            const data = doc.data();
            console.log(data.date)
            // Conversion des dates
      const startDate = data.date.toDate ? data.date.toDate() : new Date(data.date);
      const endDate = data.fin.toDate ? data.fin.toDate() : new Date(data.fin);
            return {
              id: doc.id,
              title: data.name ? data.name : 'test', // Titre de l'événement
              start: startDate, // Date de début
              end: endDate, // Date de fin
            };
          });
          setDates(firebaseEvents);
          
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      })();
    }, [refreh]);

  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h4" gutterBottom>
        Gestion des dates d'événements
      </Typography>
      <Calendar
        localizer={localizer}
        events={dates}
        selectable
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600, marginTop: 20, }}
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
      />

      {/* Boîte de dialogue pour ajouter ou modifier un événement */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {selectedEvent?.id ? "Modifier l'événement" : "Ajouter un événement"}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Titre de l'événement"
            value={newEventTitle}
            onChange={(e) => setNewEventTitle(e.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          {selectedEvent?.id && (
            <Button onClick={handleDeleteEvent} color="error">
              Supprimer
            </Button>
          )}
          <Button onClick={() => setDialogOpen(false)}>Annuler</Button>
          <Button onClick={handleSaveEvent} variant="contained">
            {selectedEvent?.id ? "Modifier" : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>
      <Swipeable type={type} eventID={eventID} date={selectedEvent}/>
    </div>
  );
};

export default Date;
