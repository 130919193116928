/* eslint-disable no-unreachable */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable import/order */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
  Grid,
  Divider,
  LinearProgress,
  Tooltip,
  TextField,
  TableHead,
  IconButton,
  Switch,
  Box,
  Link,
  Skeleton,
  MenuItem,
  Menu,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, Timestamp, updateDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
// mock
import { db } from '../../firebase.config';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { format } from 'date-fns';
import { fr, id } from 'date-fns/locale';
import { LoadingButton } from '@mui/lab';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { alpha, width } from '@mui/system';
import { shortNumber } from '../../utils/formatNumber';
import CustomDateTimePicker from '../../components/CustomDateTimePicker';
import CustomButton from '../../components/CustomButton';
import styled from '@emotion/styled';
import Swipeable from '../crm/component/swiper';
import { Variables } from '../../context/VariableContext';
import CustomTag from '../../components/customTag';

// ----------------------------------------------------------------------
// ----------- for more bouton modal
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: 5,
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function DateList() {
  const { eventID } = useParams();
  // GESTION DE LA MODIFICATION
  const { openDrawer, setOpenDrawer } = Variables();
  const { type, setType } = Variables();
  const [modif, setModif] = useState(false);
  // loading
  const [loading, setLoading] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };
  // for change value of globla quota
  const [open, setOpen] = useState(false);
  const [refreh, setRefresh] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // setModif(false);
    setRefresh((prev) => !prev);
  };

  // take tickets from firebase
  const [event, setEvent] = useState([]);
  const [dates, setDates] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [quotas, setQuotas] = useState([]);
  const [groupDate, setGroup] = useState([]);
  const [itemGroup, setItemGroup] = useState([]);



  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[0];
  };
  const handleTimestamp1 = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[1];
  };

  const handleTimestampC1 = (e) => {
    console.log('handle time stamp')
    const date = e.toDate();
    return format((new Date(date.toJSON())), 'yyyy-MM-dd', { locale: fr }).toString();
};

const handleTimestampC2 = (e) => {
    const date = e.toDate();
    return format((new Date(date.toJSON())), 'HH:mm', { locale: fr }).toString();
};




  const navigate = useNavigate();
  // recuperation des dates
  useEffect(() => {
    (async () => {
      setTickets([]);
      setDates([]);
      try {
        setLoading(true);
        // event
        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEvent(docSnap.data());
        }
        // recuperation des tickets
        const q1 = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot1 = await getDocs(q1);
        snapshot1.forEach((d) => {
          setTickets((tickets) => [...tickets, d]);
        });

        // recuperation des dates
        const q = query(collection(db, `evenement/${eventID}/seances`), orderBy('date', 'asc'));
        const snapshot = await getDocs(q);
        const tmpd = [];
        const groups = [];
        const group = [];
        snapshot.forEach((d) => {
          setDates((dates) => [...dates, d]);
          const tmpDate = format(new Date(handleTimestamp(d.data().date)), 'MMMM yyyy', { locale: fr });
          tmpd.push(d.data());
          const found = groups.find((element) => element === tmpDate);
          if (!found) {
            console.log('not found ');
            groups.push(tmpDate);
          }
        });
        console.log(groups.length);

        for (let index = 0; index < groups.length; index++) {
          const tgroup = [];
          snapshot.forEach((d) => {
            const tmpDate = format(new Date(handleTimestamp(d.data().date)), 'MMMM yyyy', { locale: fr });
            if (groups[index] === tmpDate) {
              tgroup.push(d);
            }
          });
          group.push(tgroup);
        }
        setGroup(groups);
        setItemGroup(group);
        
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [refreh]);



  const [nom, setNom] = useState('');
  const [debut, setDebut] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(null);
  const today = new Date();
  const today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes());
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  const handleCloseMenu = (e) => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const manage = () => {
    navigate(`${selectedIndex.id}`)
  }

  const handleCreateEvent = () => {
    setType(7)
    setOpenDrawer(true)
  };



  return (
    <Page title="Séance">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}
      {!loading && event.seanceActive === 1 ? (
        <>
          {!loading && dates.length === 0 ? (
            <Container>
              <Grid container spacing={2} mb={5}>
                <Grid item xs={12} md={8}>
                  <Typography variant="h4" gutterBottom>
                    Gestion des séances <br />
                    <Typography variant="body2">Créez et modifiez vos séances</Typography>
                  </Typography>
                </Grid>
                <Grid textAlign="end" item xs={6} md={4}>
                  <Button
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      Color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                    variant="contained"
                    component={RouterLink}
                    to="ajout"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    Ajouter des séances
                  </Button>
                </Grid>
              </Grid>
              <Stack spacing={1} sx={{ border: '1px solid #f2f2f2', padding: 5, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                <Typography variant="caption">Vous n'avez pas encore de seance</Typography>
                <Typography variant="caption">Cliquer sur <strong>Ajouter des seances</strong> pour créer de nouvelles seances</Typography>
              </Stack>
            </Container>
          ) : (

            <>
              <Container>
                <Grid container spacing={2} mb={5}>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h3" gutterBottom>
                      Gestion des séances <br />
                      <Typography variant="body2">Créez et modifiez vos séances</Typography>
                    </Typography>
                  </Grid>
                  <Grid textAlign="end" item xs={6} md={4}>
                    {<CustomButton type='primaire' buttonText='Ajouter des seances' component={RouterLink} to="ajout" />}
                    <CustomButton type='primaire' buttonText='Ajouter des seances' onClick={handleCreateEvent} /> 
                  </Grid>
                </Grid>
                <Stack spacing={4} >
                  {groupDate.map((group, index) => (
                    <Box key={index}>
                      <Stack direction={'row'} spacing={2}>
                      <Typography variant='subtitle1'>{group}</Typography>
                      <Typography variant='body2'>({itemGroup[index].length} seance(s))</Typography>
                      </Stack>
                      
                      <Divider />

                      {
                        /** */
                      }
                      {itemGroup[index].map((item, j) => (
                        
                        <Grid container key={j} paddingY={2} paddingX={2} marginTop={2} sx={{
                          display: 'flex', alignItems: 'center', cursor: 'pointer',
                          // border: '1px solid #5E17EB',
                          borderRadius:'7px',
                          boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 0.5px, rgba(0, 0, 0, 0.1) 0px 5px 10px -3px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px',

                        }} >
                          <Grid item xs={12} sm={5} >
                            <Stack direction='row' spacing={1} alignItems='center'>
                              <Stack justifyContent='center' alignItems='center' sx={{ color: '#5E17EB' }}>
                                <Typography variant='body2'><strong>{format(new Date(handleTimestampC1(item.data().date)), 'dd')}</strong></Typography>
                                <Typography sx={{ textTransform: 'uppercase' }} variant='body2'>{format(new Date(handleTimestampC1(item.data().date)), 'MMM', { locale: fr })}</Typography>
                              </Stack>

                              <Stack>
                                <Typography variant='body2'><strong>{item.data().name}</strong></Typography>
                                <Stack direction='row' spacing={0.5} alignItems='center'>
                                  <Typography variant='body2'>{format(new Date(handleTimestampC1(item.data().date)), 'EEE dd, MMMM yyyy', { locale: fr })} à {handleTimestampC2(item.data().date)}</Typography>

                                  {
                                    new Date(handleTimestampC1(item.data().date)) < today1 ?
                                      <CustomTag text={'Passé'} textColor={'#ffbb29'}/>
                                      : <CustomTag text={'A venir'} textColor={'#5E17EB'}/>
                                  }
                                  
                                </Stack>
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item xs={4} sm={2} >
                            <Typography variant='body2'>{item.data().participants > 0 ? item.data().participants : '-'}</Typography>
                          </Grid>
                          <Grid item xs={4} sm={2} >
                            <Typography variant='body2'>{item.data().recette > 0 ? `${shortNumber(item.data().recette)} CFA` : '-'} </Typography>
                          </Grid>

                          <Grid item xs={3} sm={2} >
                            {
                              item.data().status === 0 ?
                                <Typography variant='body2'>Masquer</Typography>
                                : item.data().status === 1 ?
                                  <CustomTag text={'Visible'} textColor={'#5E17EB'}/>
                                    : null
                            }
                          </Grid>
                          <Grid item xs={1} sm={1}>
                            <IconButton key={j} onClick={(e) => handleOpenMenu(e, item)}
                              aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={openMenu ? 'true' : undefined}>
                              <Iconify icon="material-symbols-light:more-vert" />
                            </IconButton>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                              }}
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={handleCloseMenu}
                            >

                              <MenuItem onClick={(e) => {
                                handleCloseMenu();
                                manage()
                              }} disableRipple value={0}>
                                <Typography variant='body2'>Gérer</Typography>
                              </MenuItem>

                              <MenuItem
                                onClick={(e) => {
                                  // handleCloseMenu();
                                  // goToSite()
                                }}
                                disableRipple
                                value={1}
                              >
                                <Typography variant='body2'>rendre disponible</Typography>
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  // handleCloseMenu();
                                  // handleOpenSup()
                                }}
                                disableRipple
                                value={4}
                              >
                                <Typography variant='body2'>Supprimer</Typography>
                              </MenuItem>

                            </StyledMenu>
                          </Grid>
                        </Grid>))}
                      {
                        /** */
                      }
                    </Box>
                  ))}
                </Stack>
              </Container>
              <Swipeable type={type} eventID={eventID} date={selectedIndex}/>
            </>


          )}
        </>
      ) : null}
    </Page>
  );
}
