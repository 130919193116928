/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
import React, { useState } from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { collection, doc, getCountFromServer, getDoc, query, where } from 'firebase/firestore';
import CustomTag from '../../../../components/customTag';
import Iconify from '../../../../components/Iconify';
import { db } from '../../../../firebase.config';
import { UserAuth } from '../../../../context/AuthContext';




// Composant ButtonBox
const CampagneStats = () => {
    const { user } = UserAuth();
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(10)
    const [plan, setPlan] = useState(2)

    const getData = async () => {
            try {
                setLoading(true);
    
                
                    const docRef0 = doc(db, 'organisateurs', user.uid);
                    const docSnap = await getDoc(docRef0);
    
                    if (docSnap.exists()) {
                        const orgId = docSnap.data().idOrg;
                        if (!orgId) {
                            throw new Error("orgId not found");
                        }
                        const q = query(
                            collection(db, 'evenement'),
                            where('orgId', '==', orgId),
                        );
                        const querySnapshot = await getCountFromServer(q);
                        
                    }
                
            } catch (error) {
                console.log("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };
    
        React.useEffect(() => {
            (async () => {
                getData();
            })();
        }, [user]);

    return (
        <Box sx={{
            padding: 2, height: '100%', width: '100%',
            border: '2px solid #F5EFFA', borderRadius: '10px'
        }}>
            <Stack spacing={2}>
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <Iconify icon={'material-symbols-light:campaign-outline'} sx={{ width: 27, height: 27 }} />
                    <Typography variant='subtitle1'>Campagnes</Typography>
                </Stack>

                {
                    !loading ?
                        <Stack spacing={1} direction={'row'}>
                            <Typography variant='h5'>{total}</Typography>
                            <CustomTag text={`${plan} campagnes planifié`} />
                        </Stack>
                        :
                        <Stack spacing={1} direction={'row'}>
                            <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" />
                            <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" />
                            <Skeleton sx={{ width: '100%', height: '100%' }} variant="text" />
                        </Stack>}
            </Stack>

        </Box>
    );
};

export default CampagneStats;