/* eslint-disable no-nested-ternary */
/* eslint-disable object-shorthand */
/* eslint-disable radix */
import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Alert,
  Avatar,
  Backdrop,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  styled,
} from '@mui/material';

import { Container } from '@mui/system';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  Timestamp,
  setDoc,
  serverTimestamp,
  getCountFromServer,
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import ReactQuill from 'react-quill';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import { db, storage } from '../../firebase.config';

import { UserAuth } from '../../context/AuthContext';
import Page from '../../components/Page';
import CheckOrg from '../../components/CheckOrg';
import Iconify from '../../components/Iconify';
import CustomNumber from '../../components/customNumber';
import CustomButton from '../../components/CustomButton';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingX: 1, paddingY: 5, minHeight: 400 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const params = useParams;

// ----------------------------------------------------------------------
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    // background: 'black',
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      border: 0,
      background: '#5E17EB',
      color: 'white',
    },
    '&:not(:first-of-type)': {
      // background: 'red',
      borderRadius: '7px',
    },
    '&:first-of-type': {
      // background: 'black',
      borderRadius: '7px',
    },
  },
}));
//-----------------------------------------------------------------------
export default function Organisation() {
  const { user } = UserAuth();
  const [value, setValue] = React.useState(0);
  const [organisation, setOrg] = React.useState();
  const [pastEvents, setPastEvents] = React.useState([]);
  const [brouillonEvent, setbrouillonEvent] = React.useState([]);
  const [notReadyEvents, setNotReadyEvents] = React.useState([]);

  const modules = {
    toolbar: [
      // [{ 'font': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
      ['clean'],
    ],
  };

  const formats = [
    // 'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
  ];
  const [desc, setDesc] = React.useState();
  const handleChangeQuill = (content, delta, source, editor) => {
    console.log(editor.getHTML()); // html 사용시
    console.log(JSON.stringify(editor.getContents())); // delta 사용시
    setDesc(editor.getHTML());
  };

  const helptext = ' Ce champs est requis. Veuillez le saisir!';
  // information generales------------------------
  const [editeInfoGen, setEditInfoGen] = React.useState(false);
  // information de description
  const [statusJur, setStatus] = React.useState();
  const [nomOrganisation, setNomOrg] = React.useState('');
  const [id, setId] = React.useState();
  const [numLic, setNum] = React.useState('');

  // representant legal
  const [nomField, setNom] = React.useState('');
  const [prenomsField, setPrenoms] = React.useState('');
  const [fonctionField, setFonction] = React.useState('');

  // coordonne
  const [num, setContact] = React.useState('');
  const [ville, setVille] = React.useState('');
  const [pays, setPays] = React.useState('');
  const [adresse, setAdresse] = React.useState();

  const [certifie, setCert] = React.useState(false);

  // Bio------------------------
  const [editeBio, setEditBio] = React.useState(false);

  // visuel------------------------
  const [editeVisuel, setEditVisuel] = React.useState(false);
  // upload functions
  const [selectedImage, setSelectedImage] = React.useState();
  const [imageUrl, setImageUrl] = React.useState();
  const [selectedBanner, setSelectedBanner] = React.useState();
  const [BannerUrl, setBannerUrl] = React.useState();

  // Links------------------------
  const [editeLinks, setEditLinks] = React.useState(false);
  const [webLink, setWeb] = React.useState();
  const [fbLink, setFb] = React.useState();
  const [instaLink, setInsta] = React.useState();
  const [xLink, setX] = React.useState();
  const [youLink, setYou] = React.useState();
  const [tiktokLink, setTiktok] = React.useState();
  const [msgerLink, setMsger] = React.useState();
  const [wha, setWha] = React.useState();

  // loading
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // loading

  // snack
  const [openSnack, setOpenSnack] = React.useState(false);
  const [severity, setSeverity] = React.useState('');
  const [msg, setMsg] = React.useState('');

  const handleClose = () => setOpenSnack(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        console.log(docSnap.data().idOrg);
        if (docSnap.exists()) {
          console.log(docSnap.data().idOrg);
          setNom(docSnap.data().nom);
          setPrenoms(docSnap.data().prenom);
          setFonction(docSnap.data().fonction);
          // const q = query(collection(db, 'organisations'), where('orgId', '==', docSnap.data().idOrg));
          const q = doc(db, 'organisations', docSnap.data().idOrg);
          const querySnapshot = await getDoc(q);
          setOrg(querySnapshot);
          if (querySnapshot.exists()) {
            setNomOrg(querySnapshot.data().nomOrg);
            setId(querySnapshot.id);
            setNum(querySnapshot.data().licence);
            setStatus(querySnapshot.data().type);
            setImageUrl(querySnapshot.data().idVisuel);
            setBannerUrl(querySnapshot.data().bannerVisuel);
            setCert(querySnapshot.data().certified);
          }
          const q1 = doc(db, `organisations/${docSnap.data().idOrg}/coordonnee`, `${docSnap.data().idOrg}`);
          const querySnapshot1 = await getDoc(q1);
          if (querySnapshot1.exists()) {
            setVille(querySnapshot1.data().city);
            setPays(querySnapshot1.data().country);
            setContact(querySnapshot1.data().tel);
          }

          const q2 = doc(db, `organisations/${docSnap.data().idOrg}/links`, `${docSnap.data().idOrg}`);
          const querySnapshot2 = await getDoc(q2);
          if (querySnapshot2.exists()) {
            setWeb(querySnapshot2.data().web);
            setWha(querySnapshot2.data().what);
            setInsta(querySnapshot2.data().insta);
            setYou(querySnapshot2.data().youtube);
            setFb(querySnapshot2.data().facebook);
            setMsger(querySnapshot2.data().messenger);
            setTiktok(querySnapshot2.data().tiktok);
            setX(querySnapshot2.data().x);
            // setInsta(querySnapshot1.data().insta);
          }

          const q3 = doc(db, `organisations/${docSnap.data().idOrg}/Bio`, `${docSnap.data().idOrg}`);
          const querySnapshot3 = await getDoc(q3);
          if (querySnapshot3.exists()) {
            setDesc(querySnapshot3.data().bio)
            // setInsta(querySnapshot1.data().insta);
          }

          const snapshot = await getCountFromServer(
            query(collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/contacts`))
          );
          /* setAbonne(snapshot.data().count);
          const snapshotNew = await getCountFromServer(
            query(
              collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/contacts`),
              where('newslettres', '==', true)
            )
          );
          setNews(snapshotNew.data().count);
          const snapshotPush = await getCountFromServer(
            query(
              collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/contacts`),
              where('push', '==', true)
            )
          );
          setPush(snapshotPush.data().count); */
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user.uid]);

  React.useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);
  React.useEffect(() => {
    if (selectedBanner) {
      setBannerUrl(URL.createObjectURL(selectedBanner));
    }
  }, [selectedBanner]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // fonction de mise a jour des infos generales
  const update = async () => {
    setLoading(true);

    /* if(!nomOrganisation || !nom){
        setLoading(false)
        return
      } */
    const statRef = doc(db, `organisations`, `${id}`);
    try {
      await updateDoc(statRef, {
        type: parseInt(statusJur),
        nomOrg: nomOrganisation,
        updateDate: serverTimestamp(),
        certified: certifie,
      });
      // informations sur le contact
      const refDesc = doc(db, `organisateurs`, user.uid);
      const docSnap = await getDoc(refDesc);
      if (docSnap.exists()) {
        await updateDoc(refDesc, {
          nom: nomField,
          prenom: prenomsField,
        });
      } else {
        await setDoc(refDesc, {
          nom: nomField,
          prenom: prenomsField,
        });
      }
      // informations sur le contact
      const refCoord = doc(db, `organisations/${organisation.id}/coordonnee`, `${organisation.id}`);
      const CoordSnap = await getDoc(refCoord);
      if (CoordSnap.exists()) {
        await updateDoc(refCoord, {
          city: ville,
          country: pays,
          tel: num,
        });
      } else {
        await setDoc(refCoord, {
          city: ville,
          country: pays,
          tel: num,
        });
      }
      setLoading(false);
      setOpenSnack(true);
      setMsg('Vos modifications ont bien été prise en compte');
      setSeverity('success');
      setEditInfoGen(false)
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenSnack(true);
      setMsg('une erreur ');
      setSeverity('error');
    }
  };

  // fonction de mise a jour logo
  const updateLogo = async () => {
    setLoading(true);
    const statRef = doc(db, `organisations`, `${id}`);
    try {
      if (selectedImage) {
        const storageRef = ref(storage, `/profileOrganisateur/${id}_${nomOrganisation}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
        await uploadBytes(storageRef, selectedImage).then((snapshot) => {
          getDownloadURL(snapshot.ref).then(async (downloadURL) => {
            console.log(downloadURL);
            setImageUrl(downloadURL);
            const refCoord = doc(db, `organisations`, `${organisation.id}`);
            const CoordSnap = await getDoc(refCoord);
            if (CoordSnap.exists()) {
              await updateDoc(refCoord, {
                idVisuel: downloadURL,
              });
            } else {
              await setDoc(refCoord, {
                idVisuel: downloadURL,
              });
            }
          });
        });
      }
      if (selectedBanner) {
        const storageRef = ref(storage, `/BannerOrganisateur/${id}_${nomOrganisation}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
        await uploadBytes(storageRef, selectedBanner).then((snapshot) => {
          getDownloadURL(snapshot.ref).then(async (downloadURL) => {
            console.log(downloadURL);
            setBannerUrl(downloadURL);
            const refCoord = doc(db, `organisations`, `${organisation.id}`);
            const CoordSnap = await getDoc(refCoord);
            if (CoordSnap.exists()) {
              await updateDoc(refCoord, {
                bannerVisuel: downloadURL,
              });
            } else {
              await setDoc(refCoord, {
                bannerVisuel: downloadURL,
              });
            }
          });
        });
      }
      setEditVisuel(false);
      setLoading(false);
      setOpenSnack(true);
      setMsg('Vos modifications ont bien été prise en compte');
      setSeverity('success');
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenSnack(true);
      setMsg('une erreur ');
      setSeverity('error');
    }
  };
  // fonction de mise a jour logo
  const updateLink = async () => {
    setLoading(true);
    try {
      const refCoord = doc(db, `organisations/${organisation.id}/links`, `${organisation.id}`);
      const CoordSnap = await getDoc(refCoord);
      if (CoordSnap.exists()) {
        await updateDoc(refCoord, {
          web: webLink !== '' ? webLink : null,
          insta: instaLink !== '' ? instaLink : null,
          x: xLink !== '' ? xLink : null,
          tiktok: tiktokLink !== '' ? tiktokLink : null,
          youtube: youLink !== '' ? youLink : null,
          facebook: fbLink !== '' ? fbLink : null,
          what: wha !== '' ? wha : null,
          messenger: msgerLink !== '' ? msgerLink : null,
        });
      } else {
        await setDoc(refCoord, {
          web: webLink !== '' ? webLink : null,
          insta: instaLink !== '' ? instaLink : null,
          x: xLink !== '' ? xLink : null,
          tiktok: tiktokLink !== '' ? tiktokLink : null,
          youtube: youLink !== '' ? youLink : null,
          facebook: fbLink !== '' ? fbLink : null,
          what: wha !== '' ? wha : null,
          messenger: msgerLink !== '' ? msgerLink : null,
        });
      }
      setEditVisuel(false);
      setLoading(false);
      setOpenSnack(true);
      setMsg('Vos modifications ont bien été prise en compte');
      setSeverity('success');
    } catch (error) {
      setWeb()
      setInsta()
      setX()
      setTiktok()
      setYou()
      setFb()
      setWha()
      setMsger()
      console.log(error);
      setLoading(false);
      setOpenSnack(true);
      setMsg('une erreur est survenue');
      setSeverity('error');
    }
    setEditLinks(false);
  };

  const updateBio = async () => {
    setLoading(true);
    try {
      const refCoord = doc(db, `organisations/${organisation.id}/Bio`, `${organisation.id}`);
      const CoordSnap = await getDoc(refCoord);
      if (CoordSnap.exists()) {
        await updateDoc(refCoord, {
          bio: desc,
        });
      } else {
        await setDoc(refCoord, {
          bio: desc,
        });
      }
      setEditBio(false);
      setLoading(false);
      setOpenSnack(true);
      setMsg('Vos modifications ont bien été prise en compte');
      setSeverity('success');
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenSnack(true);
      setMsg('une erreur ');
      setSeverity('error');
    }
    setEditLinks(false);
  };

  return (
    <Page title="Organisation">
      <Container>
        <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Stack spacing={3}>
          <div
            style={{
              backgroundImage: `url(${BannerUrl})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundColor: '#f2f2f2',
              height: '200px',
              width: '100%',
              display: 'flex',
              borderRadius: '7px',
              alignItems: 'center',
            }}
          >
            <div style={{
              backgroundColor: 'rgba(0, 0, 0, 0.40)',
              height: '200px',
              width: '100%',
              display: 'flex',
              padding: 5,
              borderRadius: '7px',
              alignItems: 'center',
            }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="start"
                mb={3}
                spacing={2}
                sx={{
                  width: '100%',
                  padding: 2,
                  marginX: { xs: 1, sm: 5 },
                }}
              >
                <Avatar src={imageUrl} sx={{ width: 100, height: 100 }} />
                <Stack direction="column" alignItems="start" justifyContent="center" sx={{ color: 'white', }}>
                  <Typography sx={{ color: 'white', }} variant="h5" gutterBottom>
                    {nomOrganisation}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Mise a jour le : 5 octobre 2024 233:12
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <LoadingButton
                      sx={{
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        color: 'white',
                        backgroundColor: '#5E17EB',
                        borderRadius: '7px',
                        '&:hover': {
                          backgroundColor: 'white',
                          color: '#5E17EB',

                          borderColor: '#5E17EB',
                        },
                      }}
                      onClick={() => {
                        window.open(`https://choptonticket.com/fr/organisateur/${id}`);
                      }}
                      color="secondary"
                      loading={loading}
                      loadingPosition="end"
                      variant="contained"
                    >
                      Preview
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Stack>
            </div>

          </div>
          {/** new */}
          <Box
            sx={{
              height: '100%',
              borderRadius: '7px',
              padding: 3,
              border: '2px solid gainsboro'
            }}
          >

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack>
                <Typography variant='h5' textAlign="Start">
                  <strong>Informations générales</strong>
                </Typography>
                <Typography variant="body2" textAlign="Start">
                  Ces informations seront utilisé pour vous contacter
                </Typography>
              </Stack>

              {!editeInfoGen && (
                <CustomButton buttonText='editer' type='secondaire' onClick={() => setEditInfoGen(true)} />
              )}
            </Stack>
            <Box paddingTop={2}>
              {
                editeInfoGen ?
                  <Stack spacing={3}>
                    <Stack spacing={2}>
                      <Typography variant='subtitle1'>Type *</Typography>
                      <Stack my={2} direction='row' spacing={1}>
                        <Box onClick={() => setStatus(0)} sx={statusJur === 0 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                          :
                          { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                          <Typography variant='body2'>Particulier</Typography>
                        </Box>
                        <Box onClick={() => setStatus(1)} sx={statusJur === 1 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                          :
                          { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                          <Typography variant='body2'>Entreprise</Typography>
                        </Box>
                      </Stack>
                      <Stack spacing={1} direction='row'>
                        {
                          statusJur !== 0 && <TextField fullWidth placeholder='Nom de l entreprise' value={nomOrganisation} onChange={(e) => setNomOrg(e.target.value)} />
                        }

                      </Stack>
                    </Stack>
                    <Stack spacing={2}>
                      <Typography variant='subtitle1'>Representant legale *</Typography>
                      <Stack spacing={1} direction='row'>

                        <TextField fullWidth placeholder='nom' value={nomField} onChange={(e) => setNom(e.target.value)} />
                        <TextField fullWidth placeholder='prenom' value={prenomsField} onChange={(e) => setPrenoms(e.target.value)} />

                      </Stack>
                    </Stack>
                    <Stack spacing={2}>
                      <Typography variant='subtitle1'>Coordonées *</Typography>
                      <Stack spacing={1} direction='row'>
                        <TextField fullWidth placeholder='Ville' value={ville} onChange={(e) => setVille(e.target.value)} />
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Pays</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Pays"
                            value={pays}
                            onChange={(e) => setPays(e.target.value)}
                          >
                            <MenuItem value='Côte d Ivoire'>Côte d'ivoire</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                      <CustomNumber number={num} onNumberChange={(e) => setNum(e)} />
                    </Stack>
                    <Stack direction='row' alignItems='center' sx={{ p: 2, bgcolor: '#f2f2f2', borderRadius: '7px', marginY: 2 }} dir="ltr">
                      <Checkbox onClick={() => setCert(!certifie)} checked={certifie} />
                      <Typography>je certifie sur l'honneur l'exactitude des informations ci-dessus.</Typography>
                    </Stack>
                    <Box sx={{ p: 3 }} dir="ltr">
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Stack direction='row' spacing={2} justifyContent='end'>
                            <CustomButton buttonText='Annuler' loading={loading} type='secondaire' onClick={() => setEditInfoGen(false)} />
                            <CustomButton buttonText='Enregistrer' loading={loading} type='primaire' onClick={() => update()} />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                  : !editeInfoGen &&
                    (statusJur ||
                      nomOrganisation !== '' ||
                      nomField !== '' ||
                      prenomsField !== '' ||
                      num !== '' ||
                      ville !== '' ||
                      pays !== '') ? (
                    <Box>
                      <>
                        <Box sx={{ p: 1 }} dir="ltr">
                          <Stack direction="column" spacing={1}>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Iconify icon="iconoir:home" sx={{ height: 20, width: 20 }} />
                              <Typography>
                                {statusJur === 0
                                  ? 'Particulier'
                                  : statusJur === 1
                                    ? 'Entreprise'
                                    : statusJur === 2
                                      ? 'association'
                                      : null}{' '}
                                / {nomOrganisation}
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Iconify icon="material-symbols-light:person-outline" sx={{ height: 22, width: 22 }} />
                              <Typography>
                                {nomField} {prenomsField}
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Iconify icon="ion:location-outline" sx={{ height: 20, width: 20 }} />
                              <Typography>
                                {ville} {pays}
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Iconify icon="bi:telephone" sx={{ height: 20, width: 20 }} />
                              <Typography>{num}</Typography>
                            </Stack>
                          </Stack>
                        </Box>
                      </>
                    </Box>
                  ) : null
              }


            </Box>
          </Box>
          {/** new */}

          {/** new */}
          <Box
            sx={{
              height: '100%',
              borderRadius: '7px',
              padding: 3,
              border: '2px solid gainsboro'
            }}
          >

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack>
                <Typography variant='h5' textAlign="Start">
                  <strong>Description</strong>
                </Typography>
                <Typography variant="body2" textAlign="Start">
                  Ces informations seront utilisé en tant que biographie sur votre page organisateur
                </Typography>
              </Stack>

              {!editeBio && (
                <CustomButton buttonText='editer' type='secondaire' onClick={() => setEditBio(true)} />
              )}
            </Stack>
            <Box paddingTop={2}>
              {
                editeBio ?
                  <Stack spacing={3}>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      value={desc}
                      onChange={handleChangeQuill}
                      placeholder="Saisissez une description pour votre evenement ici..."
                    />
                    <Box sx={{ p: 3 }} dir="ltr">
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Stack direction='row' spacing={2} justifyContent='end'>
                            <CustomButton buttonText='Annuler' loading={loading} type='secondaire' onClick={() => setEditBio(false)} />
                            <CustomButton buttonText='Enregistrer' loading={loading} type='primaire' onClick={() => updateBio()} />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                  : !editeBio && desc ? (
                    <Box>
                      <>
                        <Box sx={{ p: 1 }} dir="ltr">
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            formats={formats}
                            value={desc}
                          />
                        </Box>
                      </>
                    </Box>
                  ) : null
              }


            </Box>
          </Box>
          {/** new */}
          <Box
            sx={{
              height: '100%',
              borderRadius: '7px',
              padding: 3,
              border: '2px solid gainsboro'
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack>
                <Typography variant='h5' textAlign="Start">
                  <strong>Visuels</strong>
                </Typography>
                <Typography variant="body2" textAlign="Start">
                  Logo et bannière
                </Typography>
              </Stack>

              {!editeVisuel && (
                <CustomButton buttonText='editer' type='secondaire' onClick={() => setEditVisuel(true)} />
              )}
            </Stack>
            {editeVisuel ? (
              <Box>
                <>
                  {/* Description organisation */}
                  <Box sx={{ p: 3, bgcolor: '#f2f2f2', borderRadius: '7px', marginY: 2 }} dir="ltr">
                    <Grid alignItems="center" container spacing={3}>
                      <Grid item xs={10} sm={4} md={4}>
                        <Typography variant="subtitle1">Logo</Typography>
                      </Grid>
                    </Grid>

                    <Box sx={{ p: 1 }} dir="ltr">
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={8} sm={4} md={4}>
                          <Button
                            fullWidth
                            size="medium"
                            sx={{
                              marginTop: 2,
                              textTransform: 'initial',
                              boxShadow: 'none',
                              border: '1px dashed black',
                              color: 'black',
                              backgroundColor: '#f2f2f2',
                              '&:hover': {
                                backgroundColor: '#f2f2f2',
                                color: '#5E17EB',
                                borderColor: '#5E17EB',
                              },
                            }}
                            variant="contained"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              id="select-image"
                              type="file"
                              onChange={(e) => setSelectedImage(e.target.files[0])}
                            />
                            <Stack
                              sx={{
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                padding: 0,
                              }}
                            >
                              {imageUrl ? (
                                <Grid item xs={12} sm={12} md={10} padding={0}>
                                  <Grid container justifyContent="space-around" alignItems="center" padding={0}>
                                    <Grid item xs={12} sm={8} md={6} padding={0}>
                                      <img src={imageUrl} alt={imageUrl} style={{ maxHeight: 300 }} />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Stack
                                  padding={2}
                                  sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                  }}
                                >
                                  {loading ? (
                                    '... laoding'
                                  ) : (
                                    <>
                                      <Iconify icon="material-symbols:upload" sx={{ width: 30, height: 30, }} />
                                      <Typography textAlign='center' variant='caption'>Cliquer ici pour charger votre fichier</Typography>
                                      <Typography textAlign='center' variant='caption'>Seul les types de fichier .csv, .xsl sont acceptés</Typography>
                                    </>
                                  )}
                                </Stack>
                              )}
                            </Stack>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box sx={{ p: 3, bgcolor: '#f2f2f2', borderRadius: '7px', marginY: 2 }} dir="ltr">
                    <Grid alignItems="center" container spacing={3}>
                      <Grid item xs={10} sm={4} md={4}>
                        <Typography variant="subtitle1">Banniere</Typography>
                      </Grid>
                    </Grid>

                    <Box sx={{ p: 1 }} dir="ltr">
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Button
                            fullWidth
                            size="medium"
                            sx={{
                              marginTop: 2,
                              textTransform: 'initial',
                              boxShadow: 'none',
                              border: '1px dashed black',
                              color: 'black',
                              backgroundColor: '#f2f2f2',
                              '&:hover': {
                                backgroundColor: '#f2f2f2',
                                color: '#5E17EB',
                                borderColor: '#5E17EB',
                              },
                            }}
                            variant="contained"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              id="select-image"
                              type="file"
                              onChange={(e) => setSelectedBanner(e.target.files[0])}
                            />
                            <Stack
                              sx={{
                                justifyContent: 'space-around',
                                alignItems: 'center',
                              }}
                            >
                              {BannerUrl ? (
                                <Grid item xs={12} sm={12} md={10}>
                                  <Grid container justifyContent="space-around" fullWidth alignItems="center" padding={0}>
                                    <Grid item xs={12} sm={12} md={12}>
                                      <img src={BannerUrl} alt={BannerUrl} style={{ maxHeight: 350 }} />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Stack
                                  padding={2}
                                  sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                  }}
                                >
                                  {loading ? (
                                    '... laoding'
                                  ) : (
                                    <>
                                      <Iconify icon="material-symbols:upload" sx={{ width: 30, height: 30, }} />
                                      <Typography textAlign='center' variant='caption'>Cliquer ici pour charger votre fichier</Typography>
                                      <Typography textAlign='center' variant='caption'>Seul les types de fichier .csv, .xsl sont acceptés</Typography>
                                    </>
                                  )}
                                </Stack>
                              )}
                            </Stack>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Stack direction='row' spacing={2} justifyContent='end'>
                          <CustomButton buttonText='Annuler' loading={loading} type='secondaire' onClick={() => setEditVisuel(false)} />
                          <CustomButton buttonText='Enregistrer' loading={loading} type='primaire' onClick={() => updateLogo()} />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              </Box>
            ) : !editeVisuel && imageUrl ? (
              <Box>
                <>
                  <Box sx={{ p: 1 }} dir="ltr">
                    <Grid paddingY={1} alignItems="center" container spacing={1}>
                      <Grid item xs={2} sm={1} md={1}>
                        <div
                          style={{
                            backgroundImage: `url(${imageUrl})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            height: '80px',
                            display: 'flex',
                            borderRadius: '7px',
                            alignItems: 'center',
                          }}
                        />
                      </Grid>
                      <Grid item xs={10} sm={8} md={8}>
                        <div
                          style={{
                            backgroundImage: `url(${BannerUrl})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            height: '80px',
                            display: 'flex',
                            borderRadius: '7px',
                            alignItems: 'center',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </>
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{
              height: '100%',
              borderRadius: '7px',
              padding: 3,
              border: '2px solid gainsboro'
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" gutterBottom>
                Links
              </Typography>
              {!editeLinks && (
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'black',
                    backgroundColor: 'white',
                    borderRadius: '7px',
                    borderColor: 'black',
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                      borderColor: 'black',
                    },
                  }}
                  onClick={() => setEditLinks(true)}
                >
                  editer
                </Button>
              )}
            </Stack>
            {editeLinks ? (
              <Box>
                <>
                  {/* site web */}
                  <Box sx={{ p: 3, bgcolor: '#f2f2f2', borderRadius: '7px', marginY: 2 }} dir="ltr">
                    <Grid alignItems="center" container spacing={3}>
                      <Grid item xs={10} sm={4} md={4}>
                        <Typography variant="subtitle1">Site web</Typography>
                      </Grid>
                    </Grid>
                    <Box sx={{ p: 1 }} dir="ltr">
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            value={webLink}
                            label="Site web"
                            type="text"
                            fullWidth
                            onChange={(e) => setWeb(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                    <Iconify icon={'mdi:web'} />
                                    <Typography>https://</Typography>
                                  </Stack>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  {/* reseau social & messaging */}
                  <Box dir="ltr">
                    <Grid alignItems="start" container spacing={3}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box sx={{ p: 3, bgcolor: '#f2f2f2', borderRadius: '7px', marginY: 2 }}>
                          <Typography variant="subtitle1">Réseaux sociaux</Typography>
                          <Stack direction="column" paddingY={4} spacing={2}>
                            <TextField
                              value={youLink}
                              type="text"
                              fullWidth
                              onChange={(e) => setYou(e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                      <Iconify icon={'mingcute:youtube-line'} sx={{ width: 25, height: 25 }} />
                                      <Typography variant='body1'>youtube.com/</Typography>
                                    </Stack>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              value={fbLink}
                              type="text"
                              fullWidth
                              onChange={(e) => setFb(e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                      <Iconify icon={'fa-brands:facebook'} sx={{ width: 25, height: 25 }} />
                                      <Typography>facebook.com/</Typography>
                                    </Stack>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              value={instaLink}
                              type="text"
                              fullWidth
                              onChange={(e) => setInsta(e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                    <Iconify icon={'mdi:instagram'} sx={{ width: 25, height: 25 }} />
                                      <Typography>instagram.com/</Typography>
                                    </Stack>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              value={xLink}
                              type="text"
                              fullWidth
                              onChange={(e) => setX(e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                    <Iconify icon={'pajamas:x'} sx={{ width: 20, height: 20 }} />
                                      <Typography>x.com/</Typography>
                                    </Stack>
                                    
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              value={tiktokLink}
                              type="text"
                              fullWidth
                              onChange={(e) => setTiktok(e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                    <Iconify icon={'ic:baseline-tiktok'} sx={{ width: 25, height: 25 }} />
                                      <Typography>tiktok.com/@</Typography>
                                    </Stack>
                                    
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} sx={{ height: '100%' }}>
                        <Box sx={{ p: 3, bgcolor: '#f2f2f2', borderRadius: '7px', marginY: 2, height: '100%' }}>
                          <Typography variant="subtitle1">Messagerie</Typography>
                          <Stack direction="column" paddingY={4} spacing={2}>
                            <TextField
                              value={wha}
                              type="text"
                              fullWidth
                              placeholder='WhatsApp'
                              onChange={(e) => setWha(e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Iconify icon={'ic:baseline-whatsapp'} sx={{ width: 25, height: 25 }} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              value={msgerLink}
                              type="text"
                              fullWidth
                              onChange={(e) => setMsger(e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                    <Iconify icon={'mingcute:messenger-line'} sx={{ width: 25, height: 25 }} />
                                      <Typography>m.me/</Typography>
                                    </Stack>
                                    
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={2} md={2}>
                        <Button
                          fullWidth
                          variant="outlined"
                          sx={{
                            textTransform: 'initial',
                            border: '2px solid transparent',
                            color: 'black',
                            backgroundColor: 'white',
                            borderRadius: '7px',
                            borderColor: 'black',
                            '&:hover': {
                              backgroundColor: 'black',
                              color: 'white',
                              borderColor: 'black',
                            },
                          }}
                          onClick={() => setEditLinks(false)}
                        >
                          Annuler
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <LoadingButton
                          sx={{
                            textTransform: 'initial',
                            border: '2px solid transparent',
                            color: 'white',
                            backgroundColor: '#5E17EB',
                            borderRadius: '7px',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: '#5E17EB',

                              borderColor: '#5E17EB',
                            },
                          }}
                          onClick={() => updateLink()}
                          fullWidth
                          color="secondary"
                          loading={loading}
                          loadingPosition="end"
                          variant="contained"
                        >
                          Enregistrer
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>{' '}
                </>
              </Box>
            ) : !editeLinks && (webLink || fbLink || youLink || instaLink || xLink || tiktokLink || wha || msgerLink) ? (
              <Box>
                <>
                  {/*  */}

                  <Box sx={{ p: 1 }} dir="ltr">
                    {webLink && webLink !== '' && (
                      <Box sx={{ p: 1 }}>
                        <Link href={`https://${webLink}`} underline="hover">
                          {webLink}
                        </Link>
                      </Box>
                    )}
                    <Stack direction='row' spacing={1}>
                      {youLink && youLink !== '' && (
                        <IconButton aria-label="delete">
                          <Iconify icon={'mingcute:youtube-line'} sx={{ width: 25, height: 25 }} />
                        </IconButton>
                      )}
                      {fbLink && fbLink !== '' && (
                        <IconButton aria-label="delete">
                          <Iconify icon={'fa-brands:facebook'} sx={{ width: 25, height: 25 }} />
                        </IconButton>
                      )}
                      {instaLink && instaLink !== '' && (
                        <IconButton aria-label="delete">
                          <Iconify icon={'mdi:instagram'} sx={{ width: 25, height: 25 }} />
                        </IconButton>
                      )}
                      {xLink && xLink !== '' && (
                        <IconButton aria-label="delete">
                          <Iconify icon={'pajamas:x'} sx={{ width: 22, height: 22 }} />
                        </IconButton>
                      )}
                      {tiktokLink && tiktokLink !== '' && (
                        <IconButton aria-label="delete">
                          <Iconify icon={'ic:baseline-tiktok'} sx={{ width: 25, height: 25 }} />
                        </IconButton>
                      )}
                      {wha && wha !== '' && (
                        <IconButton aria-label="delete">
                          <Iconify icon={'ic:baseline-whatsapp'} sx={{ width: 25, height: 25 }} />
                        </IconButton>
                      )}
                      {msgerLink && msgerLink !== '' && (
                        <IconButton aria-label="delete">
                          <Iconify icon={'mingcute:messenger-line'} sx={{ width: 25, height: 25 }} />
                        </IconButton>
                      )}
                    </Stack>

                  </Box>

                </>
              </Box>
            ) : null}
          </Box>
        </Stack>

      </Container>
      <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={`${severity}`} sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
    </Page>
  );
}
