/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable spaced-comment */
/* eslint-disable no-unreachable-loop */
/* eslint-disable array-callback-return */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import { faker } from '@faker-js/faker';
import 'dayjs/locale/fr';
// @mui
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  Box,
  Stack,
  Button,
  TextField,
  Divider,
  FormControlLabel,
  Switch,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Link,
  IconButton,
  InputLabel,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from '@mui/material';

// components
import { DesktopDateRangePicker, LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { addDoc, collection, doc, getDoc, getDocs, query, Timestamp, where } from 'firebase/firestore';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateTime } from 'luxon';
import {
  StaticDatePicker,
  LocalizationProvider,
  DesktopDatePicker,
  DesktopDateTimePicker,
  DesktopTimePicker,
} from '@mui/x-date-pickers';

import { format } from 'date-fns';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { isNaN } from 'lodash';
import { db } from '../../firebase.config';

import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { FormProvider } from '../../components/hook-form';
import CustomDateTimePicker from '../../components/CustomDateTimePicker';
import CustomSeanceDateTimePicker from '../../components/customSeanceDateTimePicker';
import CustomButton from '../../components/CustomButton';

// sections
// Toogle Group Button
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0.5,
    width: 60,
    height: 30,
    border: 0,
    // background: 'black',
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      border: 0,
      background: '#5E17EB',
      color: 'white',
      '&:hover': {
        backgroundColor: '#5E17EB',

      },
    },
    '&:not(:first-of-type)': {
      // background: 'red',
      borderRadius: '7px',
    },
    '&:first-of-type': {
      // background: 'black',
      borderRadius: '7px',
    },
  },
}));
// ----------------------------------------------------------------------

export default function AddDate() {
  const navigate = useNavigate();
  const { eventID } = useParams();

  const [tickets, setTickets] = useState([]);
  const [seances, setSeances] = useState([{ id: Date.now(), date: null, debutTime: '', endTime: '', subtitle: '', tickets: [] }]);
  const weekDays = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [recurrenceDays, setRecurrenceDays] = useState([]);
  const [recurrenceEndDate, setRecurrenceEndDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, `evenement/${eventID}/tickets`));
        const ticketsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTickets(ticketsData);
      } catch (error) {
        console.error("Erreur de récupération des billets :", error);
      }
      setLoading(false);
    };
    fetchTickets();
  }, [eventID]);

  const addSeance = () => {
    setSeances([...seances, { id: Date.now(), date: null, debutTime: '', endTime: '', subtitle: '', tickets: [] }]);
  };

  const addRecurrentSeances = () => {
    if (recurrenceDays.length > 0 && recurrenceEndDate) {
      const newSeances = recurrenceDays.map(day => ({
        id: Date.now() + Math.random(),
        day,
        date: null,
        debutTime: '',
        endTime: '',
        subtitle: '',
        tickets: [],
      }));
      setSeances([...seances, ...newSeances]);
    }
  };

  const removeSeance = (id) => {
    setSeances(seances.filter(seance => seance.id !== id));
  };

  const handleSeanceChange = (id, field, value) => {
    setSeances(seances.map(seance => seance.id === id ? { ...seance, [field]: value } : seance));
  };

  const toggleRecurrenceDay = (day) => {
    setRecurrenceDays(prevDays =>
      prevDays.includes(day) ? prevDays.filter(d => d !== day) : [...prevDays, day]
    );
  };

  const [recurence, setRecurence] = React.useState();
  const handleChange = (event) => {
    setRecurence(event.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      for (const seance of seances) {
        await addDoc(collection(db, `evenement/${eventID}/seances`), {
          date: Timestamp.fromDate(new Date(seance.date)),
          billets: seance.tickets,
          status: 1,
          participants: 0,
          subtitle: seance.subtitle,
        });
      }
      navigate(-1);
    } catch (error) {
      console.error("Erreur d'enregistrement :", error);
    }
    setLoading(false);
  };

  return (
    <Page title="Ajout de Séance">
      <Container>

        <Typography variant="h4" sx={{ marginBottom: 2 }}>Ajoutez des séances</Typography>

        <Stack spacing={1} sx={{ border: '1px solid #ddd', borderRadius: '10px', padding: 2 }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5'>Séance Récurrente</Typography>
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                width: 120,
                marginY: 1,
                border: (theme) => `1px solid #f2f2f2`,
                borderRadius: '7px',
                flexWrap: 'wrap',
                bgcolor: '#f2f2f2',
              }}
            >
              <StyledToggleButtonGroup
                value={isRecurrent}
                exclusive
                onChange={() => setIsRecurrent(!isRecurrent)}
                aria-label="Platform"
                sx={{ color: 'red' }}
              >
                <ToggleButton value={false}>Non</ToggleButton>
                <ToggleButton value>Oui</ToggleButton>
              </StyledToggleButtonGroup>
            </Paper>
          </Stack>
          {isRecurrent && (
            <Stack spacing={2} marginTop={2} maxWidth={700} >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Recurence de votre seance</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={recurence}
                  label="Recurence de votre seance"
                  onChange={handleChange}
                >
                  <MenuItem value={0}>Hebdomadaire</MenuItem>
                  <MenuItem value={1}>Mensuel</MenuItem>
                </Select>
              </FormControl>
              <Typography>Jours de répétition</Typography>

              <Stack spacing={1} direction={'row'}>
                {weekDays.map(day => (
                  <Button
                    key={day}
                    variant={recurrenceDays.includes(day) ? 'contained' : 'outlined'}
                    onClick={() => toggleRecurrenceDay(day)}
                  >
                    {day}
                  </Button>
                ))}
              </Stack>
              <TextField
                label="Fin de la récurrence"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={recurrenceEndDate}
                onChange={(e) => setRecurrenceEndDate(e.target.value)}
                fullWidth
              />

            </Stack>
          )}
        </Stack>




        {seances.map(seance => (
          <Stack key={seance.id} sx={{ border: '1px solid #ddd', borderRadius: '10px', padding: 2, marginY: 2, maxWidth: 700 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Information sur la seance</Typography>
              <IconButton onClick={() => removeSeance(seance.id)}>
                <Iconify icon="typcn:delete" sx={{ color: 'red' }} />
              </IconButton>
            </Stack>
            <CustomSeanceDateTimePicker
              date={seance.date}
              debutTime={seance.debutTime}
              endTime={seance.endTime}
              onDateChange={value => handleSeanceChange(seance.id, 'date', value)}
              onDebutTimeChange={value => handleSeanceChange(seance.id, 'debutTime', value)}
              onEndTimeChange={value => handleSeanceChange(seance.id, 'endTime', value)}
            />
            <Typography variant='subtitle1'>Billets Associés</Typography>
          </Stack>

        ))}
        {
          !isRecurrent && <Button variant="contained" onClick={addSeance} sx={{ marginY: 2 }}>Ajouter une séance</Button>
        }
        
        <Container>
          <Stack
            direction='row'
            spacing={2}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'white',
              padding: '16px',
              // borderTop: '2px solid gainsboro',
              boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
              zIndex: 1000,
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
            }}
          >
            <CustomButton
              type='secondaire'
              buttonText='Annuler'
              loading={loading}
              onClick={() => window.history.back()}
            />
            <CustomButton
              loading={loading}
              type='primaire'
              buttonText='Créer'
              onClick={handleSubmit}
            />
          </Stack>
        </Container>

      </Container>
    </Page>
  );
}
