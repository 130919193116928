/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 250;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, eventImage, eventName, status, visibility, date, seance }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return date;
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      {/* <Box sx={{ px: 2.5, py: 2, display: 'inline-flex' }}>
        <Logo />
      </Box> */}
      <Box sx={{ px: 1, py: 1, display: 'inline-flex' }}>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            borderRadius: '7px',
            width: '100%',
            bgcolor: 'white',
          }}
        >
          <Box sx={{ width: '100%', borderRadius: '7px' }}>
            <LazyLoadImage
              src={`${eventImage}`}
              alt={`${eventName}`}
              className="img-lazy"
              width="100%"
              height={100}
              style={{ objectFit: 'cover', borderTopLeftRadius: '7px', borderTopRightRadius: '7px' }}
              effect="blur" // opacity | black-and-white
            />
          </Box>

          <Stack spacing={0.25} paddingX={2} paddingBottom={1}>
            <Typography variant='body1'><strong>{eventName}</strong></Typography>
            <Typography variant='caption'>{date ? format(new Date(handleTimestamp(date)), 'EEE dd MMM yyyy HH:mm', { locale: fr }) : null}</Typography>
            <Stack direction='row' spacing={1}>
              {
                seance === 1 ?
                  <Box
                    padding={0.25}
                    width={90}
                    sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                  >
                    Plusieurs dates
                  </Box>
                  : null
              }
              {
                status === 3 ?
                  <Box
                    padding={0.25}
                    width={90}
                    sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Iconify icon='material-symbols:lock' sx={{ height: 15, width: 15 }} />
                      <Typography variant='caption'>Privé</Typography>
                    </Stack>
                  </Box>
                  :
                  <>
                  {
                    visibility === 1 ?
                    <Box
                      padding={0.25}
                      width={90}
                      sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                    >
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <Iconify icon='mdi:lock' sx={{ height: 15, width: 15 }} />
                        <Typography variant='caption'>Privé</Typography>
                      </Stack>
                    </Box>
                    :
                    <Box
                      padding={0.25}
                      width={90}
                      sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                    >
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <Iconify icon='ic:outline-public' sx={{ height: 15, width: 15 }} />
                        <Typography variant='caption'>Public</Typography>
                      </Stack>
                    </Box>
                  }
                  </> 
                  

              }

            </Stack>
          </Stack>
        </Stack>
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: '#5E17EB', borderRightStyle: 'hidden' },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: '#5E17EB',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
