/* eslint-disable no-unused-expressions */
// @mui
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Container,
  TextField,
  CircularProgress,
  Backdrop,
  Stack,
  Box,
  MenuItem,
  Typography,
  ListSubheader,
  FormControl,
  InputLabel,
  Select,
  Card,
} from '@mui/material';
import React, { useState } from 'react';
// components

import { collection, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format } from 'date-fns';
import AppWebsiteVisits1 from '../../sections/@dashboard/app/AppWebsiteVisits1';
import { db } from '../../firebase.config';
import Page from '../../components/Page';
// sections
import { AppCurrentVisits } from '../../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function Stats() {
  const theme = useTheme();
  const { eventID } = useParams();
  const [filterType, setFiltreType] = React.useState(0);

  const [events, setEvents] = React.useState();
  const [org, setOrg] = React.useState({});
  const [tmp, setTmp] = React.useState(0);
  const [refresh, setRefresh] = React.useState(0);
  const [tickets, setTicket] = React.useState([]);
  const [stats, setStat] = React.useState([]);
  const [Totalticket, setTotalTicket] = React.useState([]);
  const [repartTicket, setrepartTicket] = React.useState([]);
  const [repartTicket1, setrepartTicket1] = React.useState([]); // repartition des ticket par canal de vente
  const [ticketN, setTicketN] = React.useState([]);

  const [debut, setDebut] = useState();
  const [fin, setFin] = useState();
  // recuperation de la séance
  const [groupDate, setGroup] = React.useState([]);
  const [itemGroup, setItemGroup] = React.useState([]);

  const createEvent = (doc) => {
    setrepartTicket([]);

    setEvents(doc);
  };

  const handleTimestamp = (e) => {

    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    return tmp[0];
  };

  const handleTimestamp1 = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[1];
  };

  const handleDebut = (e) => {
    setDebut(e);
  };

  const handleFin = (e) => {
    setFin(e);
  };

  React.useEffect(() => {
    const id = eventID;
    let e;
    const docRef0 = doc(db, 'evenement', id);
    setrepartTicket([]);
    setStat([]);

    const fetchData = async () => {
      try {
        // setLoading(true);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          e = docSnap.data();
          createEvent(e);
          console.log(e);
          setTmp(1);
          // setDebut(format(e.createDate.seconds, 'yyyy-MM-dd'))
          setDebut(handleTimestamp(e.createDate));
          setFin(new Date());
          // verification du mode seance
          if (docSnap.data().seanceActive === 1) {
            // recuperation des dates
            const q = query(collection(db, `evenement/${eventID}/seances`), orderBy('date', 'asc'));
            const snapshot = await getDocs(q);
            const tmpd = [];
            const groups = [];
            const group = [];
            snapshot.forEach((d) => {
              // setDates((dates) => [...dates, d]);
              const tmpDate = format(new Date(handleTimestamp(d.data().date)), 'MMM yyyy');
              tmpd.push(d.data());
              const found = groups.find((element) => element === tmpDate);
              if (!found) {
                console.log('not found ');
                groups.push(tmpDate);
              }
            });
            console.log(groups.length);

            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < groups.length; index++) {
              const tgroup = [];
              snapshot.forEach((d) => {
                const tmpDate = format(new Date(handleTimestamp(d.data().date)), 'MMM yyyy');
                if (groups[index] === tmpDate) {
                  tgroup.push(d);
                }
              });
              group.push(tgroup);
            }
            setGroup(groups);
            setItemGroup(group);
          }
        }

        if (events !== null) {
          const q = query(collection(db, `evenement/${eventID}/tickets`));
          const snapshot = await getDocs(q);
          setTotalTicket(snapshot.size);
          setTicketN([]);
          setrepartTicket([]);
          snapshot.forEach((d) => {
            setTicket((tickets) => [...tickets, d]);
            const repart = { label: d.data().ticket_name, value: d.data().sell };
            setrepartTicket((repartTicket) => [...repartTicket, repart]);
            setTicketN((ticketN) => [...ticketN, d.data().ticket_name]);
          });

          // vente par canal
          setrepartTicket1([]);
          const provSnap = await getDoc(doc(db, `evenement/${eventID}/provenance`, `${eventID}`));
          if (provSnap.exists()) {
            if (provSnap.data().ajout) {
              const repart = { label: 'Ajout manuel', value: provSnap.data().ajout };
              setrepartTicket1((repartTicket1) => [...repartTicket1, repart]);
            }
            if (provSnap.data().application) {
              const repart = { label: 'Application', value: provSnap.data().application };
              setrepartTicket1((repartTicket1) => [...repartTicket1, repart]);
            }
            if (provSnap.data().guichet) {
              const repart = { label: 'Guichet', value: provSnap.data().guichet };
              setrepartTicket1((repartTicket1) => [...repartTicket1, repart]);
            }
            if (provSnap.data().site) {
              const repart = { label: 'Site web', value: provSnap.data().site };
              setrepartTicket1((repartTicket1) => [...repartTicket1, repart]);
            }
          }

          const docSnap1 = await getDoc(doc(db, 'organisations', docSnap.data().orgId));
          setOrg(docSnap1.data());

          const statsSnap = await getDocs(query(collection(db, `evenement/${eventID}/stats`)));
          statsSnap.forEach((element) => {
            setStat((stats) => [...stats, element]);
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData().catch(console.error);
  }, [tmp, refresh]);

  const [sevenDays, setSevenDays] = React.useState([
    '01 jan',
    '02 jan',
    '03 jan',
    '04 jan',
    '05 jan',
    '06 jan',
    '07 jan',
  ]);

  const [sevenDaysData, setSevenDaysData] = React.useState([
    {
      name: 'Tarif normal',
      type: 'line',
      fill: 'solid',
      data: [23, 11, 22, 27, 13, 22, 37],
    },
    {
      name: 'Tarif VIP',
      type: 'line',
      fill: 'solid',
      data: [44, 55, 41, 67, 22, 43, 21],
    },
    {
      name: 'Tarif VVIP',
      type: 'line',
      fill: 'solid',
      data: [4, 60, 20, 67, 96, 30, 20],
    },
    {
      name: 'DELUX',
      type: 'line',
      fill: 'solid',
      data: [30, 25, 36, 30, 45, 35, 64],
    },
  ]);

  // backdrop
  const [openModal, setOpenModal] = useState(false);

  const [seance, setSeance] = React.useState('');
  // choix de la seance si seance active
  const handleSeance = async (e) => {
    setSeance(e.target.value);
    const index = e.target.value.split('.')
    console.log(itemGroup[index[0]][index[1]].id)
    console.log('id : ', e.target)
    setStat([])
    try {
      const statsSnap = await getDocs(query(collection(db, `evenement/${eventID}/seances/${itemGroup[index[0]][index[1]].id}/stats`)));
      statsSnap.forEach((element) => {
        setStat((stats) => [...stats, element]);
      });
    } catch (error) {
      console.log(error)
    }
  };
  const renderSelectGroup = (index) => {
    const items = itemGroup[index].map((p, j) => {
      return (
        <MenuItem key={p.id} value={`${index}.${j}`}>
          <Typography variant="caption">
            {format(new Date(handleTimestamp(p.data().date)), 'EEEE dd MMM yyyy')} - {handleTimestamp1(p.data().date)}
          </Typography>
        </MenuItem>
      );
    });
    return [<ListSubheader>{groupDate[index]}</ListSubheader>, items];
  };

  return (
    <Page title="Statistique">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openModal}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {events && (
        <Container maxWidth="xl" sx={{ marginTop: { xs: 2 } }}>

          {events.seanceActive === 1 && (
            <Stack spacing={2} sx={{ border: '2px solid gainsboro', borderRadius: '10px' }} padding={2}>
              <Typography variant='subtitle1'>Filtres</Typography>
              <Stack direction='row' spacing={1}>
                <Box onClick={() => {
                  setFiltreType(0)
                  setRefresh(!refresh)
                }} sx={filterType === 0 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                  :
                  { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                  <Typography variant='body2'>Toutes les seances</Typography>
                </Box>
                <Box onClick={() => setFiltreType(1)} sx={filterType === 1 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                  :
                  { bgcolor: '#f2f2f2', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                  <Typography variant='body2'>Seance specifique</Typography>
                </Box>
              </Stack>
              {
                filterType === 1 &&
                <FormControl fullWidth>
                  <InputLabel>Filtrer par séance</InputLabel>
                  <Select
                    label="Filtrer par séance"
                    fullWidth
                    value={seance || ''}
                    onChange={handleSeance}
                    defaultValue=""
                  >
                    {groupDate?.map((p, index) => renderSelectGroup(index))}
                  </Select>
                </FormControl>
              }

              <Box sx={{ bgcolor: 'white' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack direction="row" spacing={2}>
                    <DesktopDatePicker
                      label="depart"
                      inputFormat="DD/MM/YYYY"
                      maxDate={fin}
                      value={debut}
                      onChange={handleDebut}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />

                    <DesktopDatePicker
                      label="fin"
                      fullWidth
                      inputFormat="DD/MM/YYYY"
                      minDate={debut}
                      value={fin}
                      maxDate={Date.now()}
                      onChange={handleFin}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Box>
            </Stack>
          )}

          <Grid container spacing={3} paddingTop={2}>
            <Grid item xs={12} md={12} lg={12}>
              {stats.length !== 0 ? (
                <>

                  <AppWebsiteVisits1
                    title={`Evolution des ventes`}
                    chartLabels={sevenDays}
                    chartData={sevenDaysData}
                    chartStat={stats}
                    debut={new Date(debut)}
                    fin={new Date(fin)}
                    ticketName={ticketN}
                  />
                </>
              ) : <Card sx={{ height: 364 }}><Typography fontSize={30} paddingY={10} paddingX={5} textAlign='center'>Vous n'avez pas encore fait de ventes !</Typography></Card>}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <AppCurrentVisits
                title="Participant par tarif"
                chartData={repartTicket}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.chart.blue[0],
                  theme.palette.chart.violet[0],
                  theme.palette.chart.yellow[0],
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <AppCurrentVisits
                title="Participants par canal de vente"
                chartData={repartTicket1}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.chart.blue[0],
                  theme.palette.chart.violet[0],
                  theme.palette.chart.yellow[0],
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}
