/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
import React from 'react';
import { Box } from '@mui/material';

// Fonction utilitaire pour convertir une couleur hexadécimale en RGBA avec opacité
const hexToRgba = (hex, opacity) => {
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

// Composant ButtonBox
const CustomTag = ({ text, textColor, type }) => {
    const error = '#8F0000'
    const success = '#008F24'
    const warning = '#eab81b'
    const defaultColor = '#5E17EB'
    // eslint-disable-next-line no-unneeded-ternary
    const color = textColor ? textColor : type === 'success' ? success : type === 'error' ? error : type === 'warning' ? warning : defaultColor
    const backgroundColor = textColor ? hexToRgba(textColor, 0.1) : hexToRgba(type === 'success' ? success : type === 'error' ? error : type === 'warning' ? warning : defaultColor, 0.1); // Couleur de fond avec opacité

    return (
        <Box
            component="button"
            padding={0.5}
            paddingX={1}
            color={color}
            sx={{
                borderRadius: '35px',
                fontSize: 9,
                bgcolor: backgroundColor,
                border: `1px solid ${backgroundColor}`,
            }}
        >
            <strong>{text}</strong>
        </Box>
    );
};

export default CustomTag;