import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useLocation, useNavigate } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import CanalVente from '../communication/canal_de_vente/CanalDeVente';
import Paiements from './component/paiements';
import Settings from './component/settings';

export default function HomeFinance() {
  const [value, setValue] = React.useState('1');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === '1') {
      if (!pathname.includes('paiements')) {
        navigate(`/finance/paiements`);
      }

    }
    if (newValue === '2') {
      if (!pathname.includes('settings')) {
        navigate(`/finance/settings`);
      }

    } 
   
  };

  React.useEffect(() => {
    (async () => {
      try {
        const tmp = pathname.split('/')
        console.log(tmp[tmp.length - 1])
        if (tmp[tmp.length - 1] === 'paiements') {
            navigate(`/finance/paiements`, { replace: true });
            setValue('1')
          } 
        else if (tmp[tmp.length - 1] === 'settings') {
          navigate(`/finance/settings`, { replace: true });
          setValue('2')
        } 
      } catch (error) {
        console.log('error');
      }

    })();
  }, [pathname]);


  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{
          paddingX: 5, borderBottom: 0,  position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: 'background.paper'
        }}>
          <TabList sx={{ indicatorColor: '#5E17EB' }} onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Comptabilité" value="1" />
            <Tab label="Paramètres paiement" value="2" />
          </TabList>
        </Box>
        <Box sx={{ height: 'calc(100vh - 143px)', overflowY: 'auto' }}>
          <TabPanel sx={{ paddingX: { xs: 0, sm: 3 } }} value="1">
            <Paiements />
          </TabPanel>
          <TabPanel sx={{ paddingX: { xs: 0, sm: 3 } }} value="2">
            <Settings />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}