/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
/* eslint-disable no-bitwise */
import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate, Link as RouterLink, useParams } from 'react-router-dom';
import {
    Alert,
    AlertTitle,
    Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    IconButton,
    LinearProgress,
    Menu,
    MenuItem,
    Modal,
    Select,
    Stack,
    styled,
    Switch,
    TextField,
    Tooltip,
} from '@mui/material';

import { alpha, Container } from '@mui/system';

import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    where,
    orderBy,
    Timestamp,
    addDoc,
} from 'firebase/firestore';
import { format } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import replImage from '../../assets/images/noimage.png';
import { db } from '../../firebase.config';

import Iconify from '../Iconify';
import { UserAuth } from '../../context/AuthContext';

import 'react-lazy-load-image-component/src/effects/blur.css';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { parseInt } from 'lodash';
import fr from 'date-fns/locale/fr';
import CustomButton from '../CustomButton';
import useResponsive from '../../hooks/useResponsive';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ paddingX: 1, paddingY: 5, minHeight: 400 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'white',
    border: '1px solid white',
    borderRadius: '5px',
    boxShadow: 0,
    p: 3,
    '@media (max-width: 600px)': {
        width: '90%', // Redimensionne sur mobile
    },
};

// ----------- for more bouton modal
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 5,
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: theme.spacing(1),
        minWidth: 200,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: 5,
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 15,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

const params = useParams;

const EventList = ({ type, eventName }) => {
    // refresh
    const [refresh, setRefresh] = React.useState(false);
    const { user } = UserAuth();
    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');
    const [value, setValue] = React.useState(0);
    const [eventList, setEventList] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [recettes, setRecettes] = React.useState([]);
    const [pastEvents, setPastEvents] = React.useState([]);
    const [brouillonEvent, setbrouillonEvent] = React.useState([]);
    const [notReadyEvents, setNotReadyEvents] = React.useState([]);
    const navigate = useNavigate();

    // loading
    const [loading, setLoading] = React.useState(false);
    // loading
    const [brouillon, setBrouillon] = React.useState(false);

    const createEventList = (doc) => {
        setEvents((events) => [...events, doc]); // ajouter a la fin
    };

    const createPastEventList = (doc) => {
        setPastEvents((events) => [...events, doc]); // ajouter a la fin
    };

    const createBrouillonEventList = (doc) => {
        setbrouillonEvent((events) => [...events, doc]); // ajouter a la fin
    };

    /* const sortEvent = (events) => {
      for (let index = 0; index < events.length; index++) {
        const element = array[index];
        if(element.nomEvent ==='ConcertKS'){
          cr
        }
        
      }
    } */
    const today = new Date();
    const today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes());

    const getData = async () => {
        try {
            setLoading(true);
            setEvents([]);
            // setRecettes([]);
            setPastEvents([]);
            setbrouillonEvent([]);

            if (user) {
                const docRef0 = doc(db, 'organisateurs', user.uid);
                const docSnap = await getDoc(docRef0);

                if (docSnap.exists()) {
                    const orgId = docSnap.data().idOrg;
                    if (!orgId) {
                        throw new Error("orgId not found");
                    }

                    let q;
                    if (eventName) {
                        const endName = `${eventName}\uf8ff`; // Limite supérieure pour la requête
                        q = query(
                            collection(db, 'evenement'),
                            where('orgId', '==', orgId),
                            where('nomEvent', '>=', eventName),
                            where('nomEvent', '<=', endName),
                            orderBy('nomEvent'), // Important pour appliquer correctement le filtre
                            orderBy('dateDebut', 'desc')
                        );
                    } else {
                        q = query(
                            collection(db, 'evenement'),
                            where('orgId', '==', orgId),
                            orderBy('dateDebut', 'desc')
                        );
                    }

                    const querySnapshot = await getDocs(q);
                    const eventsList = [];
                    // const recetteList = [];

                    querySnapshot.forEach(async (snap) => {
                        const event = snap;
                        if (type === 0) {
                            eventsList.push(event); // Ajout à la liste

                        } else if (type === 1 && (event.data().status === 1 || event.data().status === 3)) {
                            eventsList.push(event);
                        } else if (type === 2 && event.data().status === 0) {
                            eventsList.push(event);
                        } else if (type === 3 && event.data().status === 2) {
                            eventsList.push(event);
                        }
                    });

                    // Mettre à jour les états en une seule fois
                    // console.log(recetteList)
                    setEvents(eventsList);
                    // setRecettes(recetteList)
                }
            }
        } catch (error) {
            console.log("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
    };

    const getRevenueData = async () => {
        try {
            setLoading(true);
            setEvents([]);
            setRecettes([]);
            setPastEvents([]);
            setbrouillonEvent([]);

            if (user) {
                const docRef0 = doc(db, 'organisateurs', user.uid);
                const docSnap = await getDoc(docRef0);

                if (docSnap.exists()) {
                    const orgId = docSnap.data().idOrg;
                    if (!orgId) {
                        throw new Error("orgId not found");
                    }

                    let q;
                    if (eventName) {
                        const endName = `${eventName}\uf8ff`; // Limite supérieure pour la requête
                        q = query(
                            collection(db, 'evenement'),
                            where('orgId', '==', orgId),
                            where('nomEvent', '>=', eventName),
                            where('nomEvent', '<=', endName),
                            orderBy('nomEvent'), // Important pour appliquer correctement le filtre
                            orderBy('dateDebut', 'desc')
                        );
                    } else {
                        q = query(
                            collection(db, 'evenement'),
                            where('orgId', '==', orgId),
                            orderBy('dateDebut', 'desc')
                        );
                    }

                    const querySnapshot = await getDocs(q);
                    const eventsList = [];
                    const recetteList = [];

                    querySnapshot.forEach(async (snap) => {
                        const event = snap;
                        if (type === 0) {
                            eventsList.push(event); // Ajout à la liste
                            const statRef = doc(db, `evenement/${event.id}/recette`, `${event.id}`);
                            const rect = await getDoc(statRef)
                            if (rect.exists()) {
                                recetteList.push(rect)
                            } else {
                                recetteList.push(null)
                            }
                        } else if (type === 1 && event.data().status === 1) {
                            eventsList.push(event);
                            const statRef = doc(db, `evenement/${event.id}/recette`, `${event.id}`);
                            const rect = await getDoc(statRef)
                            console.log(rect.data())
                            if (rect.exists()) {
                                recetteList.push(rect)
                            } else {
                                recetteList.push(null)
                            }
                        } else if (type === 2 && event.data().status === 0) {
                            eventsList.push(event);
                            const statRef = doc(db, `evenement/${event.id}/recette`, `${event.id}`);
                            const rect = await getDoc(statRef)
                            if (rect.exists()) {
                                recetteList.push(rect)
                            } else {
                                recetteList.push(null)
                            }
                        } else if (type === 3 && event.data().status === 2) {
                            eventsList.push(event);
                            const statRef = doc(db, `evenement/${event.id}/recette`, `${event.id}`);
                            const rect = await getDoc(statRef)
                            if (rect.exists()) {
                                recetteList.push(rect)
                            } else {
                                recetteList.push(null)
                            }
                        }
                    });

                    // Mettre à jour les états en une seule fois
                    console.log(recetteList)
                    setEvents(eventsList);
                    setRecettes(recetteList)
                }
            }
        } catch (error) {
            console.log("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        (async () => {
            getData();
        })();
    }, [user, type, eventName]);

    /* React.useEffect(() => {
        (async () => {
            if (type === 0) {
                eventList.forEach((doc) => {
                    // sort list
                    const element = doc;
                    if (element.data().status === 1) {
                        setEvents((events) => [...events, element]);
                    }
                    // setLoading(false);
                });
            } else if (type === 1) {
                eventList.forEach((doc) => {
                    // sort list
                    const element = doc;
                    if (element.data().status === 0) {
                        setEvents(element);
                    } 
                    // setLoading(false);
                });
            } else if (type === 2) {
                eventList.forEach((doc) => {
                    // sort list
                    const element = doc;
                    if (element.data().status === 2) {
                        createPastEventList(element);
                    }
                    // setLoading(false);
                });
            }
            else if (type === 3) {
                eventList.forEach((doc) => {
                    // sort list
                    const element = doc;
                    if (element.data().status === 1) {
                        createEventList(element);
                    } else if (element.data().status === 0) {
                        createBrouillonEventList(element);
                    } else if (element.data().status === 2) {
                        createPastEventList(element);
                    }
                    // setLoading(false);
                });
            }
        })();
    }, [type]); */


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const manage = (event) => {
        navigate(`/${event.id}/dashboard/app/`);
    };

    const manageFromMenu = () => {
        navigate(`/${events[selectedIndex].id}/dashboard/app/`);
    };

    const goToSite = () => {
        // navigate(`https://choptonticket.com/fr/${events[selectedIndex].id}`);
        const url = `https://choptonticket.com/event/${events[selectedIndex].id}`;
        window.open(url, '_blank');
    };

    // supression d'un billet
    const handleSupp = async () => {
        // handleToggle();
        setLoading(true);
        if (activeSup) {
            const sup = activeSup;
            try {
                await deleteDoc(doc(db, `evenement`, `${sup}`));
            } catch (error) {
                console.log(error.message);
                getData();
                setLoading(false);
                setOpenSup(false);
                // handleClose();
            }
            getData();
            setLoading(false);
            setOpenSup(false);
        }
        // handleClose();
    };

    // dupliquer part
    const [nomD, setNomD] = React.useState('');
    const [dateDebutD, setDateDebutD] = React.useState('');
    const [paysD, setPaysD] = React.useState('');
    const [villeD, setVilleD] = React.useState('');
    const [lieuD, setLieuD] = React.useState('');
    const [locationD, setLocationD] = React.useState('');
    const [dateFinD, setDateFinD] = React.useState('');
    const [imgD, setImgD] = React.useState(true);
    const [descD, setDescD] = React.useState(true);
    const [billetD, setBilletD] = React.useState(true);
    const [codeD, setCodeD] = React.useState(true);
    const [seanceD, setSeanceD] = React.useState(true);

    const handleDuplicate = async () => {
        // handleToggle();
        setLoading(true);
        let docRef = '';
        if (active) {
            try {
                // await deleteDoc(doc(db, `evenement`, `${id}`));
                const d = Date.now();

                docRef = await addDoc(collection(db, 'evenement'), {
                    controleurSet: false,
                    // idEvent: 202,
                    dateDebut: Timestamp.fromDate(new Date(dateDebutD)),
                    dateFin: Timestamp.fromDate(new Date(dateFinD)),
                    idVisuel: `${imgD ? active.data().idVisuel : null}`,
                    isImageSet: `${imgD}`,
                    isTicketCreated: `${billetD}`,
                    nomEvent: nomD,
                    orgId: active.data().orgId,
                    participant: 0,
                    status: 0,
                    seanceActive: active.data().seanceActive,
                    localisation: active.data().localisation,
                    city: villeD,
                    country: paysD,
                    lieu_event: lieuD,
                    info: '',
                    createDate: Timestamp.fromDate(new Date(d)),
                    type: active.data().type,
                });
                console.log('docref : ', docRef.id);
                // set desc
                const q = query(collection(db, `evenement/${active.id}/description`));
                const snapshot = await getDocs(q);
                snapshot.forEach(async (d) => {
                    await addDoc(collection(db, `evenement/${docRef.id}/description`), {
                        value: `${descD ? d.data().value : ''}`,
                    });
                });

                // set ticket
                if (billetD) {
                    const q1 = query(collection(db, `evenement/${active.id}/tickets`));
                    const snapshot1 = await getDocs(q1);
                    snapshot1.forEach(async (d) => {
                        await addDoc(collection(db, `evenement/${docRef.id}/tickets`), {
                            description: d.data().description,
                            price: d.data().price,
                            quota: d.data().quota,
                            sell: 0,
                            status: d.data().status,
                            ticket_name: d.data().ticket_name,
                        });
                    });
                }

                // set code promo
                if (codeD) {
                    const d1 = Date.now();
                    const q2 = query(collection(db, `evenement/${active.id}/codePromo`));
                    const snapshot2 = await getDocs(q2);
                    snapshot2.forEach(async (d) => {
                        if (d.data().debut && d.data().fin) {
                            // verification de la periode
                            await addDoc(collection(db, `evenement/${docRef.id}/codePromo`), {
                                // creationDate: name,
                                code: d.data().code,
                                reduc: d.data().reduc,
                                statut: d.data().statut,
                                usage: d.data().usage,
                                billet: d.data().billet,
                                nom_code: d.data().nom_code,
                                debut: Timestamp.fromDate(new Date(d.data().debut)),
                                fin: Timestamp.fromDate(new Date(d.data().fin)),
                                createDate: Timestamp.fromDate(new Date(d1)),
                            });
                        } else if (d.data().debut) {
                            await addDoc(collection(db, `evenement/${docRef.id}/codePromo`), {
                                // creationDate: name,
                                code: d.data().code,
                                reduc: d.data().reduc,
                                statut: d.data().statut,
                                usage: d.data().usage,
                                billet: d.data().billet,
                                nom_code: d.data().nom_code,
                                debut: Timestamp.fromDate(new Date(d.data().debut)),
                                createDate: Timestamp.fromDate(new Date(d1)),
                            });
                        } else if (d.data().fin) {
                            await addDoc(collection(db, `evenement/${docRef.id}/codePromo`), {
                                code: d.data().code,
                                reduc: d.data().reduc,
                                statut: d.data().statut,
                                usage: d.data().usage,
                                billet: d.data().billet,
                                nom_code: d.data().nom_code,
                                debut: Timestamp.fromDate(Date.now()),
                                fin: Timestamp.fromDate(new Date(d.data().fin)),
                                createDate: Timestamp.fromDate(new Date(d1)),
                            });
                        } else {
                            await addDoc(collection(db, `evenement/${docRef.id}/codePromo`), {
                                code: d.data().code,
                                reduc: d.data().reduc,
                                statut: d.data().statut,
                                usage: d.data().usage,
                                billet: d.data().billet,
                                nom_code: d.data().nom_code,
                                createDate: Timestamp.fromDate(new Date(d1)),
                            });
                        }
                    });
                }

                // set seance
                if (seanceD) {
                    const q3 = query(collection(db, `evenement/${active.id}/seances`));
                    const snapshot3 = await getDocs(q3);
                    snapshot3.forEach(async (d) => {
                        await addDoc(collection(db, `evenement/${docRef.id}/seances`), {
                            date: d.data().date,
                            quota: parseInt(d.data().quota),
                            billets: d.data().billets,
                            status: parseInt(1),
                            lieu_event: d.data().lieu_event,
                            city: d.data().city,
                            country: d.data().country,
                            localisation: d.data().localisation,
                            participants: parseInt(0),
                        });
                    });
                }

                navigate(`/${docRef.id}/dashboard/information`);
            } catch (error) {
                console.log(error.message);
                // handleClose();
                setLoading(true);
            }
        }
        setLoading(true);
        // handleClose();
    };

    // gestion date
    const addDebut = (e) => {
        const today = new Date();
        const tmp = new Date(e);
        const tmpF = new Date(dateFinD);
        console.log(today);
        console.log(tmp);
        if (tmp <= today) {
            // setDebutError(true);
            // setDebutErrorMsg("La valeur de champ n'est pas valie");
        } else if (tmp >= tmpF) {
            /* setDebutErrorMsg("La date de debut doit être inferrieur à la date de fin de l'événément");
            setFinErrorMsg("La date de debut doit être inferrieur à la date de fin de l'événément");
            setDebutError(true);
            setFinError(true); */
        } else {
            // setFinError(false);
            // setDebutError(false);
        }
        setDateDebutD(e);
    };

    const addFin = (e) => {
        const debutT = new Date(dateDebutD);
        const tmp = new Date(e);
        console.log(tmp);
        if (tmp <= debutT) {
            // setFinError(true);
            // setFinErrorMsg("La date de fin doit être supérieur à la date de debut de l'événément");
        } else {
            // setFinError(false);
        }

        setDateFinD(e);
    };

    // place autocomplete
    const searchOptions = {
        componentRestrictions: { country: 'CIV' },
        fields: ['address_components', 'geometry', 'icon', 'name'],
        types: ['establishment'],
    };

    const [address, setAddress] = React.useState('');
    const [lieuError, setLieuError] = React.useState(false);

    const handleSelect = async (address, placeId, suggestion) => {
        console.log(suggestion.description);
        const tmp = suggestion.description.split(',');
        setLieuD(tmp[0]);
        setVilleD(tmp[tmp.length - 2]);
        setPaysD(tmp[tmp.length - 1]);
        setAddress(suggestion.description);
        const results = await geocodeByAddress(address);
        const ll = await getLatLng(results[0]);
        setLocationD(ll);
    };

    const handlePays = (e) => {
        setPaysD(e.target.value);
    };

    // active event
    const [active, setActive] = React.useState();
    const [activeSup, setActiveSup] = React.useState();
    // modal de duplication
    const [openDupplicate, setOpenDupplicate] = React.useState(false);
    const handleOpenDupplicate = (event) => {
        setActive(event);
        setOpenDupplicate(true);
        setNomD(`${event.data().nomEvent} - copie`);
        setDateDebutD(handleTimestamp(event.data().dateDebut));
        setDateFinD(handleTimestamp(event.data().dateFin));
        setLieuD(event.data().lieu_event);
        setVilleD(event.data().city);
        setPaysD(event.data().country);
        setLocationD(event.data().localisation);
        if (event.data().seanceActive === 0) {
            setSeanceD(false);
        }
    };
    const handleCloseDupplicate = () => setOpenDupplicate(false);

    // modal de suppression
    const [openSup, setOpenSup] = React.useState(false);
    const handleOpenSup = () => {
        setActiveSup(events[selectedIndex].id);
        setOpenSup(true);
    };
    const handleCloseSup = () => setOpenSup(false);

    const handleTimestamp = (e) => {
        console.log('handle time stamp')
        const date = e.toDate();
        return date;
    };

    const [selectedIndex, setSelectedIndex] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMenu, setOpenMenu] = React.useState(false);
    const handleOpenMenu = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };
    const handleCloseMenu = (e) => {
        setAnchorEl(null);
        setOpenMenu(false);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Modal
                open={openDupplicate}
                onClose={handleCloseDupplicate}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Duplication d'evenement
                    </Typography>
                    <Divider />
                    {loading ? (
                        <Box padding={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Nom de l'évènement
                            </Typography>
                            <TextField value={nomD} onChange={(e) => setNomD(e.target.value)} fullWidth />
                            <Grid container spacing={1} marginBottom={4}>
                                <Grid item xs={6}>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Date de début
                                    </Typography>
                                    <TextField
                                        placeholder="Date de debut"
                                        id="datetime-local"
                                        type="datetime-local"
                                        value={dateDebutD}
                                        onChange={(e) => addDebut(e.target.value)}
                                        fullWidth
                                        // error={debutError ? true : false}
                                        // helperText={debutError ? debutErrorMsg : null}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Date de fin
                                    </Typography>
                                    <TextField
                                        placeholder="Date de fin"
                                        id="datetime-local1"
                                        type="datetime-local"
                                        value={dateFinD}
                                        onChange={(e) => addFin(e.target.value)}
                                        fullWidth
                                        // error={debutError ? true : false}
                                        // helperText={debutError ? debutErrorMsg : null}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PlacesAutocomplete
                                        value={lieuD}
                                        onChange={setLieuD}
                                        onSelect={handleSelect}
                                        searchOptions={searchOptions}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                {!lieuError ? (
                                                    <TextField
                                                        {...getInputProps({ placeholder: "Entrer le lieu de l'évenement" })}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                ) : (
                                                    <TextField
                                                        {...getInputProps({ placeholder: "Entrer le lieu de l'évenement" })}
                                                        error
                                                        helperText="Le lieu de l'évnement est importante. veuillez remplir ce champs"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                <Card>
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map((suggestion) => {
                                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: 2 }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer', padding: 2 };
                                                        return (
                                                            <Box
                                                                key={suggestion.placeId}
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                            >
                                                                <Typography>{suggestion.description}</Typography>
                                                            </Box>
                                                        );
                                                    })}
                                                </Card>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <TextField
                                        placeholder="Ville"
                                        value={villeD}
                                        onChange={(e) => {
                                            setVilleD(e.target.value);
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <Select
                                            placeholder="Pays"
                                            label="choix du pays"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={paysD}
                                            onChange={handlePays}
                                        >
                                            <MenuItem value={paysD}>{paysD}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Typography>Choisissez les informations à dupliquer</Typography>
                            <Grid container spacing={1} marginBottom={4} marginTop={1}>
                                <Grid item xs={10}>
                                    <Typography>L'image de couverture</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        checked={imgD}
                                        onChange={(e) => {
                                            setImgD(e.target.checked);
                                        }}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography>Description</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        checked={descD}
                                        onChange={(e) => {
                                            setDescD(e.target.checked);
                                        }}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography>Les tickets</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        checked={billetD}
                                        onChange={(e) => {
                                            setBilletD(e.target.checked);
                                        }}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography>Codes de promotion</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        checked={codeD}
                                        onChange={(e) => {
                                            setCodeD(e.target.checked);
                                        }}
                                        size="small"
                                    />
                                </Grid>
                                {active && active.data().seanceActive === 1 && (
                                    <>
                                        <Grid item xs={10}>
                                            <Typography>Les seances</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Switch
                                                checked={seanceD}
                                                onChange={(e) => {
                                                    setSeanceD(e.target.checked);
                                                }}
                                                size="small"
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid container justifyContent="flex-end" spacing={1}>
                                <Grid item xs={6} sm={4} md={4}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{
                                            textTransform: 'initial',
                                            border: '2px solid transparent',
                                            color: 'black',
                                            backgroundColor: 'white',
                                            borderRadius: '7px',
                                            borderColor: 'black',
                                            '&:hover': {
                                                backgroundColor: 'black',
                                                color: 'white',
                                                borderColor: 'black',
                                            },
                                        }}
                                        onClick={handleCloseDupplicate}
                                    >
                                        Annuler
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4}>
                                    <Button
                                        fullWidth
                                        sx={{
                                            textTransform: 'initial',
                                            border: '2px solid transparent',
                                            color: 'white',
                                            backgroundColor: '#5E17EB',
                                            borderRadius: '7px',
                                            '&:hover': {
                                                backgroundColor: 'white',
                                                color: '#5E17EB',
                                                borderColor: '#5E17EB',
                                            },
                                        }}
                                        variant="contained"
                                        onClick={() => handleDuplicate()}
                                    >
                                        Enregistrer
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Box>
            </Modal>
            <Modal
                open={openSup}
                onClose={handleCloseSup}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack spacing={4}>
                        {
                            events[selectedIndex]?.data().participant > 0 ?
                                <>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography variant="subtitle1">Impossible de supprimer l'évènement</Typography>
                                    </Box>
                                    <Box sx={{ marginTop: 5 }} dir="ltr">
                                        <Typography align='center' variant='body2'>Vous ne pouvez pas supprimer cet évènement car il a déjà été commandé par des participants.</Typography>
                                    </Box>
                                    <Stack direction='row' spacing={2} justifyContent='center'>
                                        <CustomButton type='secondaire' buttonText='Ok' onClick={handleCloseSup} />
                                    </Stack>
                                </>
                                :
                                <>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography variant="subtitle1">Supprimer l'évènement</Typography>
                                    </Box>
                                    <Box sx={{ marginTop: 5 }} dir="ltr">
                                        <Typography align='center' variant='body2'>Voulez-vous vraiment supprimer l'évènement? Cette action ne peut pas être annulée.</Typography>
                                    </Box>
                                    <Stack direction='row' spacing={2} justifyContent='center'>
                                        <CustomButton type='secondaire' buttonText='Annuler' onClick={handleCloseSup} />
                                        <CustomButton type='primaire' buttonText='Supprimer' onClick={() => handleSupp()} />
                                    </Stack>
                                </>
                        }


                    </Stack>

                </Box>

            </Modal>

            {loading && <LinearProgress />}

            <Stack>
                <Grid container sx={{ bgcolor: '#f2f2f2' }} paddingX={2} paddingY={2}>
                    <Grid item xs={7}>
                        <Typography variant='body2'><strong>Event</strong></Typography>
                    </Grid>
                    {
                        smUp && <>
                            <Grid item xs={2}>
                                <Typography variant='body2'><strong>Participants</strong></Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography variant='body2'><strong>Statut</strong></Typography>
                            </Grid>
                        </>
                    }

                </Grid>
                {
                    (events.length === 0 && !loading) &&
                    <Stack spacing={3} marginTop={2} padding={4} justifyContent='center' alignItems={'center'} sx={{ border: '2px solid gainsboro', borderRadius: '10px' }}>
                        <Iconify icon={'mdi:event-range-outline'} sx={{ height: 50, width: 50 }} />
                        <Stack justifyContent='center' alignItems={'center'}>
                            <Typography variant='subtitle1'>Pas d'événement disponible</Typography>
                            <Typography variant='body2'>Vos evenement apparaitront ici une fois créés</Typography>
                        </Stack>


                    </Stack>
                }
                {
                    events.map((event, i) => (
                        <>
                            <Grid container key={i} paddingY={2} paddingX={2} sx={{
                                display: 'flex', alignItems: 'center', cursor: 'pointer', '&:hover': {
                                    // border: '1px solid #5E17EB',
                                    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                                },
                            }} >
                                <Grid item xs={12} sm={7} onClick={() => manage(event)}>
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                        {
                                            smUp &&
                                            <Stack justifyContent='center' alignItems='center' sx={{ color: '#5E17EB' }}>
                                                <Typography variant='body2'><strong>{format(new Date(handleTimestamp(event.data().dateDebut)), 'dd')}</strong></Typography>
                                                <Typography sx={{ textTransform: 'uppercase' }} variant='body2'>{format(new Date(handleTimestamp(event.data().dateDebut)), 'MMM', { locale: fr })}</Typography>
                                            </Stack>
                                        }

                                        <LazyLoadImage
                                            key={i}
                                            src={`${event.data().idVisuel}`}
                                            alt={`${event.data().idVisuel}`}
                                            className="img-lazy"
                                            width={80}
                                            height={85}
                                            style={{ objectFit: 'cover', borderRadius: '7px' }}
                                            effect="blur" // opacity | black-and-white
                                        />
                                        <Stack>
                                            <Typography variant='body2'><strong>{event.data().name ? event.data().name : event.data().nomEvent}</strong></Typography>
                                            <Typography variant='caption'>{event.data().locationType === 1 ? 'événement en ligne' : event.data().lieu_event}</Typography>
                                            <Stack direction='row' spacing={0.5} alignItems='center'>
                                                <Typography variant='caption'>{format(new Date(handleTimestamp(event.data().dateDebut)), 'EEE dd, MMMM yyyy', { locale: fr })} à {format(new Date(handleTimestamp(event.data().dateDebut)), 'HH:mm', { locale: fr })}</Typography>

                                                {
                                                    new Date(handleTimestamp(event.data().dateFin)) < today1 ?
                                                        <Box
                                                            component="button"
                                                            padding={0.5}
                                                            color='#7E1F86'
                                                            sx={{
                                                                borderRadius: '35px',
                                                                fontSize: 10,
                                                                backgroundColor: '#F5EFFA',
                                                                border: '0.5px solid #B991DE',
                                                            }}
                                                        >
                                                            Passé
                                                        </Box>

                                                        : new Date(handleTimestamp(event.data().dateFin)) === today1 ?
                                                            <Box
                                                                component="button"
                                                                padding={0.5}
                                                                color='#7E1F86'
                                                                sx={{
                                                                    borderRadius: '35px',
                                                                    fontSize: 10,
                                                                    backgroundColor: '#F5EFFA',
                                                                    border: '0.5px solid #B991DE',
                                                                }}
                                                            >
                                                                en cours
                                                            </Box>
                                                            : <Box
                                                                component="button"
                                                                padding={0.5}
                                                                color='#7E1F86'
                                                                sx={{
                                                                    borderRadius: '35px',
                                                                    fontSize: 10,
                                                                    backgroundColor: '#F5EFFA',
                                                                    border: '0.5px solid #B991DE',
                                                                }}
                                                            >
                                                                A venir
                                                            </Box>
                                                }
                                            </Stack>
                                            <Stack direction='row' spacing={1}>
                                                {
                                                    event.data().seanceActive === 1 ?
                                                        <Box
                                                            padding={0.25}
                                                            width={90}
                                                            sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                                                        >
                                                            Plusieurs dates
                                                        </Box>
                                                        : null
                                                }
                                                {
                                                    event.data().status === 3 ?
                                                        <Box
                                                            padding={0.25}
                                                            width={90}
                                                            sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                                                        >
                                                            <Stack direction='row' spacing={1} alignItems='center'>
                                                                <Iconify icon='material-symbols:lock' sx={{ height: 15, width: 15 }} />
                                                                <Typography variant='caption'>Privé</Typography>
                                                            </Stack>
                                                        </Box>

                                                        :
                                                        <>
                                                            {
                                                                event.data().visibility === 1 ?
                                                                    <Box
                                                                        padding={0.25}
                                                                        width={90}
                                                                        sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                                                                    >
                                                                        <Stack direction='row' spacing={1} alignItems='center'>
                                                                            <Iconify icon='material-symbols:lock' sx={{ height: 15, width: 15 }} />
                                                                            <Typography variant='caption'>Privé</Typography>
                                                                        </Stack>
                                                                    </Box>
                                                                    :
                                                                    <Box
                                                                        padding={0.25}
                                                                        width={90}
                                                                        sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                                                                    >
                                                                        <Stack direction='row' spacing={1} alignItems='center'>
                                                                            <Iconify icon='ic:outline-public' sx={{ height: 15, width: 15 }} />
                                                                            <Typography variant='caption'>Public</Typography>
                                                                        </Stack>
                                                                    </Box>
                                                            }
                                                        </>


                                                }

                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} sm={2} onClick={() => manage(event)} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'left' } }}>
                                    <Typography variant='body2'>{event.data().participant > 0 ? `${event.data().participant} ${!smUp ? 'Participants' : ''}` : '-'}</Typography>
                                </Grid>

                                <Grid item xs={3} sm={2} onClick={() => manage(event)}>
                                    {
                                        event.data().status === 0 ?
                                            <Typography variant='body2'>Brouillon</Typography>
                                            : event.data().status === 1 ?
                                                <Typography variant='body2'>Publié</Typography>
                                                : event.data().status === 2 ?
                                                    <Typography variant='body2'>Clos</Typography>
                                                    : null
                                    }
                                </Grid>
                                <Grid item xs={1} sm={1} >
                                    <IconButton sx={{ bgcolor: '#f2f2f2' }} key={i} onClick={(e) => handleOpenMenu(e, i)}
                                        aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openMenu ? 'true' : undefined}>
                                        <Iconify icon="material-symbols-light:more-vert" />
                                    </IconButton>
                                    <StyledMenu
                                        id="demo-customized-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'demo-customized-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={openMenu}
                                        onClose={handleCloseMenu}
                                    >

                                        <MenuItem onClick={(e) => {
                                            handleCloseMenu();
                                            manageFromMenu()
                                        }} disableRipple value={0}>
                                            <Typography variant='body2'>Gérer</Typography>
                                        </MenuItem>

                                        <MenuItem
                                            onClick={(e) => {
                                                handleCloseMenu();
                                                goToSite()
                                            }}
                                            disableRipple
                                            value={1}
                                        >
                                            <Typography variant='body2'>Voir</Typography>
                                        </MenuItem>

                                        <MenuItem
                                            onClick={(e) => {
                                                // handleCloseMenu();
                                                // handleOpenDeleteModal()
                                            }}
                                            disableRipple
                                            value={2}
                                        >
                                            <Typography variant='body2'>Copier le lien</Typography>
                                        </MenuItem>
                                        {
                                            /**
                                             <MenuItem
                                            onClick={(e) => {
                                                handleCloseMenu();
                                                handleOpenDupplicate(event)
                                            }}
                                            disableRipple
                                            value={3}
                                        >
                                            <Typography variant='body2'>Dupliquer</Typography>
                                        </MenuItem>
                                             * 
                                             */
                                        }

                                        <MenuItem
                                            onClick={(e) => {
                                                handleCloseMenu();
                                                handleOpenSup()
                                            }}
                                            disableRipple
                                            value={4}
                                        >
                                            <Typography variant='body2'>Supprimer</Typography>
                                        </MenuItem>

                                    </StyledMenu>
                                </Grid>
                            </Grid>
                            <Divider />
                        </>
                    ))
                }

            </Stack>
        </Box>
    );
}

export default EventList;
