import { margin } from "@mui/system";
import { Document, Image, Page, View, Text, StyleSheet } from "@react-pdf/renderer";
import { useEffect, useState } from "react";


const logoUrl = "https://firebasestorage.googleapis.com/v0/b/event-86cbf.appspot.com/o/FCMImages%2Fappicon.png?alt=media&token=72c4997a-ce99-462b-8a8b-c0fce183d2dc"; // Remplacez par le logo réel


// Styles
const styles = StyleSheet.create({
    page: { paddingVertical: 50, paddingHorizontal: 30, fontFamily: "Helvetica", color: "#333" },
    header: { marginBottom: 20, textAlign: "center", backgroundColor: "#f7f7f7", padding: 10 },
    logo: { width: 30, height: 30, marginBottom: 10, borderRadius: '5px' },
    title: { fontSize: 16, fontWeight: "bold", marginBottom: 10 },
    description: { fontSize: 8, color: "#555", textAlign: "center", marginBottom: 15 },
    section: { marginBottom: 15 },
    sectionTitle: { fontSize: 12, fontWeight: "bold", marginBottom: 10 },
    text: { fontSize: 8, marginBottom: 5 },
    caption: { fontSize: 6, marginBottom: 5 },
    row1: { flexDirection: "row", alignItems: "baseline", justifyContent: "space-between", marginBottom: 15 },
    row: { flexDirection: "row", justifyContent: "space-between", marginBottom: 15 },
    halfRow: { width: "50%", marginBottom: 15 },
    column: { flex: 1, marginRight: 10 },
    table: { marginVertical: 10 },
    tableRow: { flexDirection: "row", borderBottom: "1px solid #ccc", padding: 4 },
    tableHeader: { fontWeight: "bold", backgroundColor: "#f7f7f7" },
    cell: { width: "16.5%", fontSize: 7, padding: 4 },
    cell1: { width: "50%", fontSize: 7, padding: 4 },
    standardCell: { flex: 1, fontSize: 7, padding: 4 },
    footer: { marginTop: 20, fontSize: 6, textAlign: "center", color: "#777" },
    Gfooter: {
        position: "absolute",
        bottom: 40, // Positionnement du bas de page
        width: "100%",
        textAlign: "center",
        fontSize: 8,
        color: "#777",
        borderTop: "1px solid #ccc",
        paddingTop: 5,
    },
});

// Données
const info = {
    devise:'CFA',
    editionDate: "16 Novembre 2024",
    invoiceNumber: "943988",
    description:
        "Reddition des comptes en euro valant facture émise au nom et pour le compte de par Weezevent, conformément au contrat de commissionnaire opaque en billetterie n°O356855.",
    event: {
        seance: 0,
        name: "Festival de Musique",
        date: "15 Novembre 2024",
        location: "Paris, France",
    },
    organizer: {
        type: 0,
        denomination: 'MGROUP',
        name: "John Doe",
        email: "john.doe@example.com",
        phone: "+33 6 12 34 56 78",
    },
    participants: [
        { ticketType: "Plein tarif", quantity: 200 },
        { ticketType: "Tarif réduit", quantity: 100 },
        { ticketType: "VIP", quantity: 50 },
    ],
    revenue: [
        { item: "Billets vendus", amount: 10500 },
        { item: "Remboursements", amount: -500 },
        { item: "code promo", amount: -500 },
        { item: "Commissions d'affiliation", amount: -1200 },
        { item: "Revenu net", amount: 8800 },
    ],
};

const PdfRedditionDef = ({ data }) => (
    <Document>
        
        <Page size="A4" style={styles.page}>
            {/* En-tête */}
            <View style={styles.row1}>
                <Text style={styles.caption}>Abidjan, le {new Date().getDate()} {new Date().getMonth()} {new Date().getFullYear()}</Text>
                <Image src={logoUrl} style={styles.logo} />
            </View>
            <View style={styles.header}>

                <Text style={styles.title}>
                    REDDITION DES COMPTES DÉFINITIVE N°{data?.invoiceNumber}
                </Text>
                <Text style={styles.description}>{data?.description}</Text>
            </View>

            {/* Informations générales (organisateur & événement côte à côte) */}
            <View style={styles.row}>
                {/* Informations de l'événement */}
                <View style={styles.column}>
                    <Text style={styles.sectionTitle}>Informations de l'événement</Text>
                    <Text style={styles.text}>Nom : {data?.event?.name}</Text>
                    <Text style={styles.text}>Date : {data?.event?.date}</Text>
                    <Text style={styles.text}>Lieu : {data?.event?.location}</Text>
                </View>

                {/* Informations de l'organisateur */}
                <View style={styles.column}>
                    <Text style={styles.sectionTitle}>Informations de l'Organisateur</Text>
                    <Text style={styles.text}>Nom : {data?.organizer?.name}</Text>
                    <Text style={styles.text}>Email : {data?.organizer?.email}</Text>
                    <Text style={styles.text}>Téléphone : {data?.organizer?.phone}</Text>
                </View>
            </View>

            {/* Tableau des participants */}
            <View style={styles.halfRow}>
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>
                        Participants
                    </Text>
                    <View style={styles.table}>
                        <View style={[styles.tableRow, styles.tableHeader]}>
                            <Text style={styles.standardCell}>Type de Billet</Text>
                            <Text style={styles.cell}>Quantité</Text>
                        </View>
                        {data?.participants?.map((participant, index) => (
                            <View key={index} style={styles.tableRow}>
                                <Text style={styles.standardCell}> participant {participant?.ticketType}</Text>
                                <Text style={styles.cell}>{participant?.quantity}</Text>
                            </View>

                        ))}
                        <View style={[styles.tableRow, styles.tableHeader]}>
                            <Text style={styles.standardCell}>TOTAL PARTICIPANT</Text>
                            <Text style={styles.cell}>1200</Text>
                        </View>
                    </View>
                </View>
            </View>


            {/* Bordereau de recettes */}
            <View style={styles.section}>
                <Text style={styles.sectionTitle}>Bordereau de Recettes</Text>
                <View style={styles.table}>
                    <View style={[styles.tableRow, styles.tableHeader]}>
                        <Text style={styles.cell1} />
                        <Text style={styles.cell}>TOTAL HT (€)</Text>
                        <Text style={styles.cell}>TOTAL TVA (€)</Text>
                        <Text style={styles.cell}>TOTAL TOTAL TTC (€)</Text>
                    </View>
                    {data?.revenue?.map((item, index) => (
                        <View key={index} style={styles.tableRow}>
                            <Text style={styles.cell1}>{item.item}</Text>
                            <Text style={styles.cell}>{item.amount.toFixed(2)}</Text>
                            <Text style={styles.cell}>{item.amount.toFixed(2)}</Text>
                            <Text style={styles.cell}>{item.amount.toFixed(2)}</Text>
                        </View>
                    ))}
                </View>
            </View>

            {/* Total final */}
            <View style={styles.section}>
                <Text style={styles.text}>
                    <Text style={{ fontWeight: "bold" }}>Total Net : </Text>
                    {data?.revenue?.find((item) => item.item === "Revenu net").amount.toFixed(2)} €
                </Text>
            </View>

            {/* Pied de page */}
            <View style={styles.footer}>
                <Text>Ces donnees sont extraites d'un logiciel satisfaisant aux conditions d'inalterabilite, de securisation, de conservation et d'archivage des donnees.
                    Il existe deux manieres de calculer les arrondis lies a la TVA : faire la somme des lignes d'articles arrondies ou faire l'arrondi de la somme des lignes d'articles.
                </Text>

            </View>
            {/* Pied de page */}
            <View style={styles.Gfooter}>
                <Text> Weezevent SAS au capital de 72212,00 € - SIRET 503 715 401 00017 - EU VAT FR93503715401 - APE 5829C</Text>
                <Text>Choptonticket - Tous droits réservés © {new Date().getFullYear()}</Text>
            </View>
        </Page>
        {data?.seances?.map((item, index) => (
            <Page size="A4" style={styles.page} key={index}>
                {/* En-tête */}
                <View style={styles.row1}>
                    <Text style={styles.caption}>Abidjan, le {new Date().getDate()} {new Date().getMonth()} {new Date().getFullYear()}</Text>
                    <Image src={logoUrl} style={styles.logo} />
                </View>
                <View style={styles.header}>

                    <Text style={styles.title}>
                        REDDITION DES COMPTES DÉFINITIVE N°{data.invoiceNumber}
                    </Text>
                    <Text style={styles.description}>{data.description}</Text>
                </View>

                {/* Informations générales (organisateur & événement côte à côte) */}
                <View style={styles.row}>
                    {/* Informations de l'événement */}
                    <View style={styles.column}>
                        <Text style={styles.sectionTitle}>Informations de l'Événement</Text>
                        <Text style={styles.text}>Nom : {data.event.name}</Text>
                        <Text style={styles.text}>Date : {data.event.date}</Text>
                        <Text style={styles.text}>Lieu : {data.event.location}</Text>
                    </View>

                    {/* Informations de l'organisateur */}
                    <View style={styles.column}>
                        <Text style={styles.sectionTitle}>Informations de l'Organisateur</Text>
                        <Text style={styles.text}>Nom : {data.organizer.name}</Text>
                        <Text style={styles.text}>Email : {data.organizer.email}</Text>
                        <Text style={styles.text}>Téléphone : {data.organizer.phone}</Text>
                    </View>
                </View>

                {/* Tableau des participants */}
                <View style={styles.halfRow}>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>
                            Participants
                        </Text>
                        <View style={styles.table}>
                            <View style={[styles.tableRow, styles.tableHeader]}>
                                <Text style={styles.standardCell}>Type de Billet</Text>
                                <Text style={styles.cell}>Quantité</Text>
                            </View>
                            {data.participants.map((participant, index) => (
                                <View key={index} style={styles.tableRow}>
                                    <Text style={styles.standardCell}> participant {participant.ticketType}</Text>
                                    <Text style={styles.cell}>{participant.quantity}</Text>
                                </View>

                            ))}
                            <View style={[styles.tableRow, styles.tableHeader]}>
                                <Text style={styles.standardCell}>TOTAL PARTICIPANT</Text>
                                <Text style={styles.cell}>1200</Text>
                            </View>
                        </View>
                    </View>
                </View>


                {/* Bordereau de recettes */}
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Bordereau de Recettes</Text>
                    <View style={styles.table}>
                        <View style={[styles.tableRow, styles.tableHeader]}>
                            <Text style={styles.cell1} />
                            <Text style={styles.cell}>TOTAL HT (€)</Text>
                            <Text style={styles.cell}>TOTAL TVA (€)</Text>
                            <Text style={styles.cell}>TOTAL TOTAL TTC (€)</Text>
                        </View>
                        {data.revenue.map((item, index) => (
                            <View key={index} style={styles.tableRow}>
                                <Text style={styles.cell1}>{item.item}</Text>
                                <Text style={styles.cell}>{item.amount.toFixed(2)}</Text>
                                <Text style={styles.cell}>{item.amount.toFixed(2)}</Text>
                                <Text style={styles.cell}>{item.amount.toFixed(2)}</Text>
                            </View>
                        ))}
                    </View>
                </View>

                {/* Total final */}
                <View style={styles.section}>
                    <Text style={styles.text}>
                        <Text style={{ fontWeight: "bold" }}>Total Net : </Text>
                        {data.revenue.find((item) => item.item === "Revenu net").amount.toFixed(2)} €
                    </Text>
                </View>

                {/* Pied de page */}
                <View style={styles.footer}>
                    <Text>Ces donnees sont extraites d'un logiciel satisfaisant aux conditions d'inalterabilite, de securisation, de conservation et d'archivage des donnees.
                        Il existe deux manieres de calculer les arrondis lies a la TVA : faire la somme des lignes d'articles arrondies ou faire l'arrondi de la somme des lignes d'articles.
                    </Text>

                </View>
                {/* Pied de page */}
                <View style={styles.Gfooter}>
                    <Text> Weezevent SAS au capital de 72212,00 € - SIRET 503 715 401 00017 - EU VAT FR93503715401 - APE 5829C</Text>
                    <Text>Choptonticket - Tous droits réservés © {new Date().getFullYear()}</Text>
                </View>
            </Page>
        ))}


    </Document>
);

export default PdfRedditionDef;