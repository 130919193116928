import React from 'react';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Icon,
  Stack,
  Typography,
} from '@mui/material';
import Iconify from '../Iconify';
import CustomButton from '../CustomButton';

function AppWorks() {
  // state = { visible: false };
  return (
    <div id="works" className="block aboutBlock bgPrimaire" >
      <div className="container-fluid">
        <div className="contentHolder">
          <div>
            <Stack sx={{ color: 'white' }}>
              <Typography color='white' textAlign="center" variant="h5">
                Comment ça marche
              </Typography>
              <Typography color='white' textAlign="center" variant="h2">
                C'EST SUPER SIMPLE
              </Typography>
            </Stack>

          </div>
          <Grid container spacing={5} textAlign="start" paddingY={5} sx={{ color: 'white' }}>
            <Grid item xs={12} sm={4}>
              <Box sx={{ height: '100%',  }}>
                <Box
                  padding={1}
                  sx={{ bgcolor: '#1F1F1F', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', color: 'white' }}
                >
                  <Typography>Avant l'evenement</Typography>
                </Box>
                <Box
                  padding={2}
                  sx={{ border: '2px solid #1F1F1F', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}
                >
                  <Typography color='white' paddingBottom={3} variant="h4">
                    Créez votre billetterie gratuitement
                  </Typography>
                  <Typography fontSize={18}>
                    Sans formation technique, créez facilement votre événement en quelques étapes simples : inscrivez-vous gratuitement, ajoutez
                    les détails et personnalisez votre page. Configurez la billetterie en un clic, choisissez les types
                    de billets, définissez les prix, et utilisez nos outils intégrés pour promouvoir votre événement
                    efficacement.
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box
                padding={1}
                sx={{ bgcolor: '#1F1F1F', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', color: 'white' }}
              >
                <Typography>Pendant l'evenement</Typography>
              </Box>
              <Box
                padding={2}
                sx={{ border: '2px solid #1F1F1F', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}
              >
                <Typography color='white' paddingBottom={3} variant="h4">
                  Contrôle et enregistrement des accès à l’évènement
                </Typography>
                <Typography fontSize={18}>
                  assurez une gestion fluide des participants et des ventes de billets grâce à un tableau de bord en
                  temps réel. Simplifiez la gestion de la liste des participants avec des outils comme le contrôle
                  d'accès et les codes QR. Facilitez l'expérience d'achat avec des méthodes de paiement sécurisées. En
                  cas de besoin, vous pouvez bénéficiez d'une assistance sur place.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                padding={1}
                sx={{ bgcolor: '#1F1F1F', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', color: 'white' }}
              >
                <Typography>Après l'evenement</Typography>
              </Box>
              <Box
                padding={2}
                sx={{ border: '2px solid #1F1F1F', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}
              >
                <Typography color='white' paddingBottom={3} variant="h4">
                  reversement des revenus & Point Financier
                </Typography>
                <Typography fontSize={18}>
                  accédez à un rapport complet sur les ventes, la participation et d'autres statistiques clés pour
                  évaluer le succès de votre événement. Grace a nos outils, fidélisez votre public et créez vous une communauté. Archivez facilement
                  toutes les données de l'événement pour un evenement futur, simplifiant ainsi la planification
                  d'événements ultérieurs.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <CustomButton type='primaire' size="large" buttonText='Démarer maintenant' href="/bo"/>
        </div>
      </div>
    </div>
  );
}

export default AppWorks;
