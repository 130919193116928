/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable no-continue */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useState } from 'react';
import {
  Box, Button, Typography, TextField, Select, MenuItem, Stack, Accordion, AccordionSummary, AccordionDetails, IconButton,
  Grid,
  Card
} from '@mui/material';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { collection, addDoc, setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase.config';
import Iconify from '../../../../components/Iconify';
import CustomButton from '../../../../components/CustomButton';
import { Variables } from '../../../../context/VariableContext';

export default function ImportationDrawer({ orgID }) {
  const { setOpenDrawer, generalReload,setGeneralReload } = Variables();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({});

  const defaultMappings = {
    'email': 'email',
    'telephone': 'telephone',
    'commune': 'commune',
    'ville': 'ville',
    'region': 'region',
    'pays': 'pays',
    'nom': 'nom',
    'prenoms': 'prenoms',
    'abonne': 'abonne',
    'age': 'age',
    'genre': 'genre',
    'date dernier achat': 'dateLastOrder',
    'total billets achetes': 'totalTicketBuyed',
    'total depense': 'totalDepense',
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);
    setFileName(file.name);
    Papa.parse(file, {
      complete: (result) => {
        const { data, meta } = result;
        setHeaders(meta.fields || []);
        setCsvData(data);

        const initialMapping = {};
        (meta.fields || []).forEach(header => {
          initialMapping[header] = defaultMappings[header.toLowerCase()] || '';
        });
        console.log(initialMapping)
        setFieldMapping(initialMapping);
        setLoading(false);
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  const handleFieldMappingChange = (csvHeader, targetField) => {
    setFieldMapping((prevMapping) => ({
      ...prevMapping,
      [csvHeader]: targetField,
    }));
  };

  const handleRemoveFile = () => {
    setCsvData([]);
    setFileName(null);
    setHeaders([]);
    setFieldMapping({});
  };

  const handleUploadToFirebase = async () => {
    setLoading(true);
    try {
      const collectionRef = collection(db, `organisations/${orgID}/crm/${orgID}/contacts`);
      const mappedData = csvData.map((row) => {
        const transformedRow = {};
        headers.forEach((header) => {
          const targetField = fieldMapping[header];
          if (targetField) {
            transformedRow[targetField] = row[header];
          }
        });
        return transformedRow;
      });

      for (const data of mappedData) {
        console.log(data)
        const email = data.email?.toLowerCase();
        if (!email) continue;

        const docRef = doc(collectionRef, email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Mise à jour si le document existe déjà
          // await updateDoc(docRef, { ...data, updatedAt: new Date() });
        } else {
          // Création du document si non existant
          await setDoc(docRef, {
            depenses: data?.totalDepense,
            nom: data?.nom,
            prenom: data?.prenoms,
            tickets: parseInt(data?.totalTicketBuyed),
            push: data?.abonne === true ? true : false,
            newslettres: data?.abonne === true ? true : false,
            tel: '',
            commune: '',
            region: '',
            ville: '',
            pays: '',
            gender: data?.genre,
            age: '24-30',
            dernierAchatDate: new Date(),
            createDate: new Date(),
            updateDate: new Date()
          });
        }
      }

      handleRemoveFile();
      setGeneralReload(!generalReload)
      setOpenDrawer(false)
    } catch (error) {
      console.error("Erreur lors de l'importation des contacts:", error);
    }
    setLoading(false);
  };

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <Box paddingBottom={3} paddingX={3} sx={{ height: { xs: '84vh', sm: ' 80.25vh', xl: '84.25vh' }, overflowX: 'auto', overflowY: 'auto' }}>
        <Typography variant="h5">Importer des utilisateurs</Typography>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          sx={{ bgcolor: '#F0F0F0', mb: 2, mt: 3 }}
        >
          <AccordionSummary
            expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Stack>
              <Stack spacing={1} direction="row" alignItems="center">
                <Iconify icon="mdi:calendar-outline" sx={{ width: 20, height: 20 }} />
                <Typography variant='body2'><strong>0/2000</strong> contacts importé ce mois</Typography>
              </Stack>
              <Typography textAlign={'center'} variant='body2'>{csvData.length === 1 ? `1 nouveau contact sera ajouter ` : csvData.length > 1 ? `${csvData.length} nouveaux contacts seront ajouter` : ''}</Typography>
            </Stack>

          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='caption'>
              Vous pouvez importer au maximum 2000 contacts inscrits. Au-delà de cette limite, des coûts
              supplémentataires seront requis.
            </Typography>
          </AccordionDetails>
        </Accordion>
        {fileName ? (
          <Stack spacing={2}>
            <Stack sx={{ border: '2px solid #f2f2f2', bgcolor: '#f2f2f2', borderRadius: '5px', padding: 3 }} justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
              <Box >
                {fileName}
              </Box>
              <IconButton onClick={handleRemoveFile}>
                <Iconify icon={'material-symbols-light:delete-outline'} />
              </IconButton>
            </Stack>
            <Stack sx={{ border: '2px solid #f2f2f2', borderRadius: '5px', padding: 3 }} spacing={2}>
              <Stack>
                <Typography variant='subtitle1'>Correspondance des informations</Typography>
                <Typography variant='caption'>Veuillez sélectionner les colonnes de votre fichier CSV correspondant aux informations demandées</Typography>
              </Stack>
              {headers.map((header) => (
                <Stack key={header} spacing={1}>
                  <Typography variant='subtitle2'>{header}</Typography>
                  <Select value={fieldMapping[header]} onChange={(e) => handleFieldMappingChange(header, e.target.value)}>
                    <MenuItem value="">-- Sélectionner un champ --</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="telephone">Téléphone</MenuItem>
                    <MenuItem value="nom">Nom</MenuItem>
                    <MenuItem value="prenoms">Prénom</MenuItem>
                    <MenuItem value="commune">Commune</MenuItem>
                    <MenuItem value="ville">Ville</MenuItem>
                    <MenuItem value="region">Région</MenuItem>
                    <MenuItem value="pays">Pays</MenuItem>
                    <MenuItem value="age">Age</MenuItem>
                    <MenuItem value="genre">Genre</MenuItem>
                    <MenuItem value="totalTicketBuyed">Total billets achetés</MenuItem>
                    <MenuItem value="totalDepense">Total dépense</MenuItem>
                    <MenuItem value="dateLastOrder">Date de dernier achat</MenuItem>
                    <MenuItem value="abonne">Abonné</MenuItem>
                  </Select>
                  
                </Stack>
              ))}
            </Stack>
          </Stack>
        ) : (
          <Box>
            <Card sx={{ padding: 3 }}>
              <Stack direction="row" justifyItems="center">
                <Iconify />
                <Typography variant="body2"><strong>Importer des contacts depuis un fihier csv</strong></Typography>
              </Stack>
              <Typography variant='body2'>Les colonnnes de votre fichier CSV doivent avoir les titres suivants :</Typography>
              <Grid container spacing={1} paddingY={2}>
                <Grid item xs={3}>
                  <Box
                    sx={{
                      borderRadius: '5px',
                      bgcolor: '#F0F0F0',
                      color: '#5E17EB',
                      padding: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant='caption'>nom</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    sx={{
                      borderRadius: '5px',
                      bgcolor: '#F0F0F0',
                      color: '#5E17EB',
                      padding: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant='caption'>prenoms</Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    sx={{
                      borderRadius: '5px',
                      bgcolor: '#F0F0F0',
                      color: '#5E17EB',
                      padding: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant='caption'>email</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      borderRadius: '5px',
                      bgcolor: '#F0F0F0',
                      color: '#5E17EB',
                      padding: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant='caption'>abonne (yes/no)</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Button
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: '#5E17EB',
                  backgroundColor: 'white',
                  borderRadius: '7px',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: '#5E17EB',
                    borderColor: 'white',
                  },
                }}
                variant="text"
              >
                Télécharger un exemple de fichier CSV
              </Button>
            </Card>
            <Button
              fullWidth
              size="medium"
              sx={{
                marginTop: 2,
                textTransform: 'initial',
                boxShadow: 'none',
                border: '1px dashed gainsboro',
                color: 'black',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#5E17EB',
                  borderColor: '#5E17EB',
                },
              }}
              variant="contained"
              component="label"
            >
              <input
                hidden
                accept=".csv"
                id="select-image"
                type="file"
                onChange={handleFileUpload}
              />
              <Stack
                padding={2}
                sx={{
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  color: 'black',
                }}
              >
                {loading ? (
                  '... chargement'
                ) : (
                  <>
                    <Iconify icon="material-symbols:upload" sx={{ width: 30, height: 30, color: 'gainsboro' }} />
                    <Typography variant='caption'>Cliquer ici pour charger votre fichier</Typography>
                    <Typography variant='caption'>Seul les types de fichier .csv, .xsl sont acceptés</Typography>
                  </>
                )}
              </Stack>
            </Button>
          </Box>
        )}
      </Box>

      <Stack direction='row' spacing={2} padding={2} justifyContent={'right'} alignItems={'center'} sx={{ borderTop: '1px solid gainsboro' }}>
        <CustomButton onClick={() => {
          setOpenDrawer(false);
        }} disabled={loading} type={'secondaire'} buttonText={'Annuler'} />
        <CustomButton onClick={handleUploadToFirebase} disabled={!(csvData.length > 0)} type={'primaire'} buttonText={`Importer contacts (${csvData.length})`} />
      </Stack>
    </Box>
  );
}
