import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import { doc, getDoc } from 'firebase/firestore';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { Variables } from '../../context/VariableContext';
import { db } from '../../firebase.config';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  background:'white',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: 'calc(100vh - 64px)',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(2),
  background:'white',
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP ,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {

  const { eventID } = useParams();
  const [open, setOpen] = useState(false);
  const { generalReload, setGeneralReload } = Variables();
  const [event, setEvent] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        console.log('-------------------------------> reload ')
        setEvent();
        // setLoading(true);
        console.log(eventID);
        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);
        console.log(docSnap.data());
        if (docSnap.exists()) {
          setEvent(docSnap.data())
        }
        // setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [generalReload, eventID]);

  

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} eventName={event != null ? event.nomEvent : null} date={event != null ? event.dateDebut : null} visibility={event != null ? event.visibility : null} status={event != null ? event.status : null} eventImage={event != null ? event.idVisuel : null} seance={event?.seanceActive}/>
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
