import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,

    Stack,

    Button,

    Container,
    Typography,

    Grid,

    Box,
    Modal,
    TextField,

    MenuItem,

    IconButton,
    Backdrop,
    CircularProgress,
    Menu,
    Divider,
    styled,
    alpha,
    LinearProgress,
    Avatar,
} from '@mui/material';
// components
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from 'firebase/firestore';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import { db } from '../../../firebase.config';
import CheckCard from '../../../components/CheckCard';
import { UserAuth } from '../../../context/AuthContext';
import CustomButton from '../../../components/CustomButton';
import TikTokLinkAccount from './parameter/intégration/TiktokLinkAccount';
import FacebookLinkAccount from './parameter/intégration/FacebookLinkAccount';


export default function Parameter() {
    return (
        <Page title="Campagne">
            <Box>

                <Container sx={{ marginTop: { xs: 3 }, }}>
                    <CheckCard />
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" mb={2} >
                        <Typography variant="h2" gutterBottom>
                            Paramêtres
                        </Typography>
                    </Stack>
                    <Stack mb={4} direction='row' spacing={1}>
                        <Box sx={{ bgcolor: '#5E17EB', color: 'white', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1}>
                            <Typography variant='body2'>Intégration</Typography>
                        </Box>
                        <Box sx={{ bgcolor: '#f2f2f2', borderRadius: '30px', cursor: 'pointer' }} paddingX={2} paddingY={1}>
                            <Typography variant='body2'>Personnalisations</Typography>
                        </Box>
                    </Stack>



                    <Stack paddingTop={3} spacing={3}>
                        <TikTokLinkAccount />
                        <FacebookLinkAccount />
                        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-evenly' sx={{ border: '1px solid gainsboro', borderRadius: '7px' }}>
                            <Stack padding={4} direction='row'>
                                <Avatar sx={{ width: 70, height: 70, bgcolor: '#1877f2' }}>
                                    <Iconify icon='logos:facebook' sx={{ width: 50, height: 50 }} />
                                </Avatar>

                                <Box sx={{ display: 'flex', alignItems: 'end' }}>
                                    <Iconify icon='mdi:link' sx={{ width: 20, height: 20 }} />
                                </Box>

                            </Stack>
                            <Stack spacing={2} padding={2}>
                                <Typography variant='h4'>Facebook</Typography>
                                <Typography variant='body2'>Configurez des campagnes publicitaires et partagez des événements sur votre page Facebook lorsque vous connectez votre compte avec Choptonticket.
                                </Typography>
                                <CustomButton type='tertiaire' buttonText='Connecter votre compte Facebook' />
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
            </Box>

        </Page>
    );
}
