/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
  TableHead,
  Grid,
  Box,
  TextField,
  MenuItem,
  useTheme,
  IconButton,
  Backdrop,
  CircularProgress,
  Skeleton,
  InputAdornment,
  Menu,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import Iconify from '../../../../components/Iconify';

// mock

import { db } from '../../../../firebase.config';
import CheckCard from '../../../../components/CheckCard';
import { UserAuth } from '../../../../context/AuthContext';
import { Variables } from '../../../../context/VariableContext';
import Swipeable from '../swiper';
import CustomButton from '../../../../components/CustomButton';

// ----------------------------------------------------------------------
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 7,
    border: '0.5px solid black',
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

// for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function PopulationsList() {
  const { eventID } = useParams();
  const { user } = UserAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const [orgID, setOrgID] = React.useState();

  // loading
  const [loading, setLoading] = React.useState(false);

  // GESTION DE LA MODIFICATION
  const { openDrawer, setOpenDrawer } = Variables();
  const { type, setType } = Variables();

  const handleImportation = () => {
    setType(6);
    setOpenDrawer(!openDrawer);
  };


  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [refreh, setRefresh] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    // setModif(false);
    setRefresh((prev) => !prev);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  // take operateurs from firebase
  const [populations, setPopulation] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      setPopulation([]);
      try {
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', `${user.uid}`);
        const docSnap = await getDoc(docRef0);
        const q = query(collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/populations`));
        const snapshot = await getDocs(q);
        snapshot.forEach(async (d) => {
          setPopulation((populations) => [...populations, d]);
        });
        setOrgID(docSnap.data().idOrg);
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [refreh, openDrawer]);

  

  const deletePopulation = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette population ?")) {
      await deleteDoc(doc(db, `organisations/${orgID}/crm/${orgID}/populations`, id));
    }
  };
  

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (e) => {
    setAnchorEl(null);
    if (e === 0) {
      // setOpenDupplicate(true);
    } else if (e === 2) {
      // setOpenClos(true);
    }
  };

  return (
    <Page title="Population">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ marginTop: { xs: 3 } }}>
        <CheckCard />
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" mb={5}>
          <Typography variant="h2" gutterBottom>
            Populations
          </Typography>
        </Stack>
       
        <Grid container spacing={2} paddingBottom={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs={12} sm={4} md={6}>
            {loading ? (
              <Skeleton width="20%">
                <Typography>.</Typography>
              </Skeleton>
            ) : (
              <Typography variant="h5">{populations.length} contacts</Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={8} md={6}>

            <Stack direction='row' spacing={2}>
              <TextField InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="iconoir:search" />
                  </InputAdornment>
                ),
              }} placeholder="Rechercher" fullWidth />
              <CustomButton type='primaire' buttonText='Créer une population' onClick={handleImportation} />
            </Stack>
          </Grid>
        </Grid>
        <Box>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="caption table">
                <TableHead sx={{ bgcolor: '#f2f2f2' }}>
                  <TableRow>
                    <TableCell>Populations</TableCell>
                    <TableCell>Taille</TableCell>
                    <TableCell>Créer le</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <caption>
                    <Skeleton variant="rounded" width="100%" />
                  </caption>
                ) : populations.length === 0 && !loading ? (
                  <caption>
                    <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                      <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                      <Typography variant="caption">Aucune donnée</Typography>
                    </Stack>
                  </caption>
                ) : (
                  <TableBody>
                    {populations.map((contact, index) => (
                      <TableRow key={index} hover>
                        <TableCell
                          id={contact.id}
                          // onClick={(e) => handleModif(index)}
                          key={contact.id}
                          component="th"
                          scope="row"
                          onClick={() => {navigate(`${contact.id}`);}}
                        >
                          <Stack direction='row' alignItems='center' spacing={1}>
                            <Box style={{ backgroundColor: '#F5EFFA', borderRadius: '7px', width: 50, height: 50, border: '1px solid gainsboro', fontSize: "1.5rem", cursor: "pointer", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              {contact.data().emoji}
                            </Box>
                            <Typography variant='body2'><strong>{contact.data().name}</strong></Typography>
                          </Stack>

                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={() => {navigate(`${contact.id}`);}}>
                          <Typography variant='body2'>5</Typography>
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={() => {navigate(`${contact.id}`);}}>
                          <Typography variant='body2'>{contact.data().age ? contact.data().age : '-'}</Typography>
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => { }}>
                          <IconButton
                          onClick={handleClick}
                            aria-label="more"
                            id="long-button"
                            sx={{ border: '1px solid gainsboro' }}
                          >
                            <Iconify icon="mi:options-horizontal" />
                          </IconButton>
                          {
                            /** */
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                              }}
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={handleCloseMenu}
                            >

                              <MenuItem onClick={handleCloseMenu} disableRipple value={1}>
                                Modifier
                              </MenuItem>


                              <MenuItem
                                onClick={(e) => {
                                  handleCloseMenu(e.target.value);
                                }}
                                disableRipple
                                value={0}
                              >
                                Supprimer
                              </MenuItem>
                              <MenuItem onClick={() => deletePopulation(contact.id)}>Supprimer</MenuItem>

                            </StyledMenu>
                            /** */
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Box>
      </Container>
      <Swipeable type={type} organisation={orgID} />
    </Page>
  );
}
