import React, { createContext, useContext, useState, useEffect } from 'react';




const VariableContext = createContext();

export const VariableContextProvider = ({ children }) => {
    
    const [openDrawer, setOpenDrawer] = useState(false)
    const [isMounted, setIsMounted] = React.useState(false);
    const [openLogin, setOpenLogin] = useState(false)
    
    const [openReservation, setOpenReservation] = useState(false)
    const [type, setType] = useState(1)

    const [generalReload, setGeneralReload] = useState(false)

    

    return (
        <VariableContext.Provider value={{ openDrawer, setOpenDrawer, isMounted, setIsMounted, openLogin, setOpenLogin, openReservation, setOpenReservation, type, setType, generalReload, setGeneralReload }}>
            {children}
        </VariableContext.Provider>
    );
};

export const Variables = () => useContext(VariableContext);